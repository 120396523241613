import { Label, HTMLSelect, EditableText, TextArea, ButtonGroup, Button, Divider, Switch, Slider, MenuItem, Menu, InputGroupProps2, Tag, Colors, Card, Popover } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { SketchPicker, ColorResult } from 'react-color';
import React, { useEffect, useState, useCallback, useContext, isValidElement} from 'react';
import { DisplayFieldTypes, Tab, Units } from '../Data/enums';
import { commentDisplayParams, displayMap, layerDisplayParams} from '../Data/display-mappings';
import { setProperty, hasKey, getProperty, getValue, getCustomValue, deleteProperty, getLayerValue } from '../utils/ts-helpers';
import { Color, IColor } from '../utils/Color';
import { NumericInput } from './Fields/NumericInput';
import { PercentageSlider} from './Fields/PercentageSlider'
import { LabeledSwitch } from './Fields/LabeledSwitch';
import { CsvInput } from './Fields/CsvInput';
import { Input } from './Fields/Input';
import { BloxTypes } from '../Data/BloxSchema/base-blox';
import { IconNames } from '@blueprintjs/icons';
import { v4 as uuidv4 } from 'uuid'
import { useFabuState } from '../hooks/state/use-fabu-state';
import { Row, Column } from '../Layout/layouts';
import { materialFieldMap } from '../Data/material-mappings';
import { BloxSvg } from './svg/BloxSvg';
import { UnsavedChangesContext } from '../hooks/state/unsaved-changes-provider';
import { useReadUser } from '../hooks/DataFetching/use-fetch-user';
import { useAuth0 } from '@auth0/auth0-react';
import { ItemPredicate, MultiSelect } from '@blueprintjs/select';
import { Tooltip2 } from "@blueprintjs/popover2";

const filterItem: ItemPredicate<string> = (query, val, _index, exactMatch) => {
    const normalizedItem = val.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedItem === normalizedQuery;
    } else {
        return normalizedItem.indexOf(normalizedQuery) >= 0;
    }
}

export const RightPanelBloxInfo: React.FC = () => {

    const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
    const [processBloxes, setProcessBloxes] = useFabuState('processBloxes');
    const [processIsReadOnly, ] = useFabuState('processIsReadOnly');
    const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);
    const {user} = useAuth0();
    const userId = user?.sub;
    const {data: userData} = useReadUser(userId);
    const showSimulateTab = !!userData?.enabledFeatures.stackSimulator;

    const readOnly = processIsReadOnly;

    // holds the user input without saving. Allows validation on input
    const [inputValueDict, setInputValueDict] = useState({} as {[key: string]: string});
    const [inputCustomValueDict, setCustomInputValueDict] = useState({} as {[key: string]: string});

    const selectedBloxIdx = processBloxes.findIndex(blox => blox.id === selectedBloxId);
    const selectedBlox = processBloxes[selectedBloxIdx];

    // backward compatibility for multi layer blox
    if (selectedBlox && selectedBlox.bloxType === BloxTypes.StartBlox && !selectedBlox.layers) {
        selectedBlox.layers = [{
            "layerLabel": selectedBlox.layerLabel ?? "",
            "layerThickness": selectedBlox.layerThickness ?? 10,
            "layerSimulationThickness": 1000,
            "layerSimulationThicknessUnit": Units.NM,
            "layerColor": selectedBlox.layerColor ?? {R: 255, G: 255, B: 255, A: 1},
            showLabel: true,
            materialId: selectedBlox.id
        }];
    }

    const [tab, setTab] = useState(Tab.EXPERIMENTAL);

    useEffect(() => {
        if (!selectedBlox) setSelectedBloxId(processBloxes[0].id)  
    }, [processBloxes, selectedBlox, setSelectedBloxId])

    const bloxDisplayMap = selectedBlox ? displayMap[selectedBlox.bloxType] : {};
    const fieldsToDisplay = selectedBlox ? Object.keys(bloxDisplayMap).filter(property => bloxDisplayMap[property].tabs.includes(tab)) : [];
    const customFields = selectedBlox && selectedBlox.customFields ? Object.keys(selectedBlox.customFields) : [];

    fieldsToDisplay.sort((a,b) => bloxDisplayMap[a].order -  bloxDisplayMap[b].order);

    const onChangeValue = useCallback((property: string, newVal: any) => {
        let temp = {};
        // account for properties added after save
        // change them if the user can also see them as defined in the display map
        if (!hasKey(processBloxes[selectedBloxIdx], property)) {
            temp = {[property]: newVal}
        }

        const updatedBlox = {
            ...processBloxes[selectedBloxIdx],
            ...temp
        }

        
        // backwards compat as saved bloxes do not have toolName
        if (hasKey(updatedBlox, property) || property === 'toolName') {
            setProperty(updatedBlox, property, newVal);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox);
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, setProcessBloxes, setEditorUnsavedChanges, selectedBloxIdx]);

    const onChangeLayerValue = useCallback((layerIndex: number, property: string, newVal: any) => {
        
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.layers) {
            let temp = {};

            // account for properties added after save
            // change them if the user can also see them as defined in the display map
            if (!hasKey(updatedBlox.layers[layerIndex], property)) {
                temp = {[property]: newVal}
            }

            updatedBlox.layers[layerIndex] = {
                ...updatedBlox.layers[layerIndex],
                ...temp
            }

            if (hasKey(updatedBlox.layers[layerIndex], property)) {
                setProperty(updatedBlox.layers[layerIndex], property, newVal);
            }
        }

        
        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);

    }, [processBloxes, setProcessBloxes, setEditorUnsavedChanges, selectedBloxIdx]);

    const onChangeCustomValue = useCallback((id: string, newVal: any) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }
        
        if (updatedBlox.customFields && hasKey(updatedBlox.customFields, id)) {
            const curr = getProperty(updatedBlox.customFields, id);
            curr[1] = newVal;
            setProperty(updatedBlox.customFields, id, curr);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);


        setEditorUnsavedChanges(true);
    }, [processBloxes, setProcessBloxes, selectedBloxIdx, setEditorUnsavedChanges]);

    const onChangeCustomLabel = useCallback((id: string, newLabel: any) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.customFields && hasKey(updatedBlox.customFields, id)) {
            const curr = getProperty(updatedBlox.customFields, id);
            curr[0] = newLabel;
            setProperty(updatedBlox.customFields, id, curr);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, setProcessBloxes, setEditorUnsavedChanges, selectedBloxIdx]);

    const addCustomField = useCallback(() => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.customFields) {
            const fieldCount = Object.keys(updatedBlox.customFields).length;
            const customFieldId = `${updatedBlox.id}-custom-${uuidv4()}`
            setProperty(updatedBlox.customFields, customFieldId, [`New Field ${fieldCount+1}`, ""]);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);
        
        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);

    const deleteCustomField = useCallback((id:string) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.customFields && hasKey(updatedBlox.customFields, id)) {
            // delete it
            deleteProperty(updatedBlox.customFields, id);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);


    const addLayer = useCallback(() => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        const oldLayers = updatedBlox.layers ?? [];
        const showLabel = oldLayers.length > 1 ? oldLayers[0].showLabel : true;

        const newLayerIndex = oldLayers.length + 1;

        let colorValue = 255 - (newLayerIndex * 20);
        if (colorValue < 0) {
            colorValue = 0;
        }

        updatedBlox.layers = [
            ...oldLayers,
            {
                layerColor: {R: colorValue, G: colorValue, B: colorValue, A: 1},
                layerThickness: 8,
                layerSimulationThickness: 100,
                layerSimulationThicknessUnit: Units.NM,
                layerLabel: "",
                showLabel: showLabel,
                materialId: uuidv4()
            }
        ]

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);

    const deleteLayer = useCallback((layerIndex: number) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        const layers = updatedBlox.layers ?? [];
        layers.splice(layerIndex, 1);
        updatedBlox.layers = layers;

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);
        
        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);
        
    if (!selectedBlox)
        return <span>No Blox Selected</span>;

    // used for bloxes that have multiple layers
    const getLayerDisplayElement = (layerIndex: number) => {
        let labelValue = getLayerValue(layerIndex, "layerLabel", selectedBlox);
        let thicknessValue = getLayerValue(layerIndex, "layerThickness", selectedBlox);
        let simulationThicknessValue = getLayerValue(layerIndex, "layerSimulationThickness", selectedBlox);
        let simulationThicknessUnitValue = getLayerValue(layerIndex, "layerSimulationThicknessUnit", selectedBlox);
        let colorValue = getLayerValue(layerIndex, "layerColor", selectedBlox);
        let showLabel = getLayerValue(layerIndex, "showLabel", selectedBlox);


        // check for default values
        if (labelValue === undefined)
            labelValue = layerDisplayParams["layerLabel"].defaultValue;
        if (thicknessValue === undefined)
            thicknessValue = layerDisplayParams["layerThickness"].defaultValue;
        if (simulationThicknessValue === undefined)
            simulationThicknessValue = layerDisplayParams["layerSimulationThickness"].defaultValue;
        if (simulationThicknessUnitValue === undefined && layerDisplayParams["layerSimulationThickness"].units && layerDisplayParams["layerSimulationThickness"].units.length > 0)
            simulationThicknessUnitValue = layerDisplayParams["layerSimulationThickness"].units[0];
        if (colorValue === undefined)
            colorValue = layerDisplayParams["layerColor"].defaultValue;
        if (showLabel === undefined)
            showLabel = layerDisplayParams["showLabel"].defaultValue;
        
        let thicknessNumVal = Number(thicknessValue);
        thicknessNumVal = typeof thicknessNumVal !== 'number' ? 0 : thicknessNumVal;

        let simulationThicknessNum = Number(simulationThicknessValue);
        simulationThicknessNum = typeof simulationThicknessNum !== 'number' ? 0 : simulationThicknessNum;
        
        const layerUnits = layerDisplayParams["layerSimulationThickness"].units ?? [];

        const unitElement = (
            <Popover2 className={'inputMenuPopover'}
                content={
                    <Menu className={'inputMenu'}>
                        {layerUnits.map(unit => <MenuItem onClick={() => onChangeLayerValue(layerIndex, 'layerSimulationThicknessUnit', unit)} text={unit} key={unit} />) }
                    </Menu>
                }
                disabled={readOnly}
                placement="bottom-end"
            >
                <Button text={simulationThicknessUnitValue ? simulationThicknessUnitValue.toString(): Units.NM} disabled={readOnly} minimal={true} rightIcon="caret-down"/>
            </Popover2>
        );

        return <div key={`${selectedBlox.id}-layer--${layerIndex}`} style={{"width":"100%", "paddingBottom": "15px"}}>
            <Input
                key={`${selectedBlox.id}-layer--${layerIndex}-label`}
                id={`${selectedBlox.id}-layer--${layerIndex}-label`}
                label={"Layer " + (layerIndex + 1)}
                inputValueDictState={[inputValueDict, setInputValueDict]}
                inputGroupProps={{
                    title: layerDisplayParams["layerLabel"].placeholder,
                    value: labelValue as string ?? "",
                    placeholder: layerDisplayParams["layerLabel"].placeholder,            
                    disabled: readOnly
                }}
                onChange={(val) => onChangeLayerValue(layerIndex, "layerLabel", val)} 
                inputButton={layerIndex > 0 ? <Button disabled={processIsReadOnly} className="removeButton" small={true} minimal={true} icon={IconNames.REMOVE} onClick={() => deleteLayer(layerIndex)} /> : <></>}/>
            <Switch disabled={readOnly} checked={showLabel as boolean} label={"Show Label"} onChange={() => onChangeLayerValue(layerIndex, "showLabel", !showLabel)}  />
            <Row>
                {tab === Tab.DISPLAY ? <Column style={{ "width": "100%", "paddingTop": "5px" }}>
                    <Slider
                        key={`${selectedBlox.id}-layer--${layerIndex}-layerThickness`}
                        min={1}
                        max={50}
                        stepSize={1}
                        labelStepSize={49}
                        onChange={(val) => onChangeLayerValue(layerIndex, "layerThickness", val)}
                        value={thicknessNumVal} 
                        disabled = {readOnly} />
                </Column> : <NumericInput
                    key={`${selectedBlox.id}-layer--${layerIndex}-layerSimulationThickness`}
                    id={`${selectedBlox.id}-layer--${layerIndex}-layerSimulationThickness`}
                    label={layerDisplayParams["layerSimulationThickness"].label}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    inputGroupProps={{
                        title: layerDisplayParams["layerSimulationThickness"].placeholder,
                        value: simulationThicknessNum.toString(),
                        placeholder: layerDisplayParams["layerSimulationThickness"].placeholder,
                        disabled: readOnly,
                        rightElement: unitElement
                    }} 
                    onChange={(val) => onChangeLayerValue(layerIndex, "layerSimulationThickness", val)} 
                />}
                <Column style={{justifyContent: 'flex-end', paddingBottom: '13px'}} >
                    <Popover
                        className={'inputMenuPopover'}
                        content={
                            <SketchPicker
                                color={{
                                    r: (colorValue as IColor).R,
                                    g: (colorValue as IColor).G,
                                    b: (colorValue as IColor).B,
                                    a: (colorValue as IColor).A
                                }}
                                onChange={(newColor: ColorResult) => {
                                    onChangeLayerValue(layerIndex, "layerColor", {
                                        R: newColor.rgb.r,
                                        G: newColor.rgb.g,
                                        B: newColor.rgb.b,
                                        A: newColor.rgb.a ?? 1
                                    });
                                }}
                            />
                        }
                        placement='auto'
                        disabled={readOnly}
                    >
                        <div
                            className='colorPreview'
                            style={{ display: 'inline-block', backgroundColor: Color.getCSS(colorValue as IColor) }}
                        />
                    </Popover>
                </Column>
            </Row>    
            <Divider/>
        </div>;
    }

    const getDisplayElement = (property: string, index?: number) => {
        const { fieldType,
            label,
            placeholder,
            options,
            isNumber,
            units,
            defaultValue,
            stepSize,
            materialFilter,
            maxSliderValue,
            infoContent
        } = property === "commentField" ? commentDisplayParams : bloxDisplayMap[property];
        let value = getValue(property, selectedBlox);
        
        // check for default values
        if (value === undefined && defaultValue !== undefined)
            value = defaultValue;

        let rightEl;

        const inputGroupProps: InputGroupProps2 = {
            title: placeholder, 
            rightElement: rightEl,
            value: value as string ?? "", 
            disabled: readOnly,
            placeholder: placeholder 
        }

        if (units) {
            const unitPropertyName = `${property}Unit`;
            const currUnit = hasKey(selectedBlox, unitPropertyName) ? (getProperty(selectedBlox, unitPropertyName) as string) : units.length > 0 ? units[0] : undefined;
            inputGroupProps.rightElement = (
                <Popover2 className={'inputMenuPopover'}
                    content={
                        <Menu className={'inputMenu'}>
                            {units.map(unit => <MenuItem onClick={() => onChangeValue(unitPropertyName, unit)} text={unit} key={unit} />) }
                        </Menu>
                    }
                    disabled={readOnly}
                    placement="bottom-end"
                >
                    <Button text={currUnit} disabled={readOnly} minimal={true} rightIcon="caret-down"/>
                </Popover2>
            );
        }

        let materialLabels: string[] = [];
        
        const previousMaterials: {[label: string] : number} = {}
        const previousDopants: {[label: string] : number} = {}
        if (fieldType === DisplayFieldTypes.MultiMaterialSelect) {
            const previousBloxes = processBloxes.slice(0, selectedBloxIdx+1);
            interface Material {
                materialId: string,
                materialLabel:string,
                isResist: boolean
            }
            let materials = previousBloxes.reduce((mtls: Material[], blox, idx) => {
                const materialParams = materialFieldMap[blox.bloxType];

                if (blox.bloxType === BloxTypes.StartBlox) {
                    // special case for start blox
                    if (blox.layers) {
                        blox.layers.forEach((layer, index) => {
                            const materialLabel = layer.layerLabel ? layer.layerLabel : "Unknown Material";
                            
                            if (materialLabel in previousMaterials) { 
                                previousMaterials[materialLabel] += 1
                            } else {
                                previousMaterials[materialLabel] = 1;

                                mtls.push({ 
                                    materialId: layer.materialId, 
                                    materialLabel: materialLabel,
                                    isResist: false
                                });
                            }
                        });
                    } else {
                        // backward compatability
                        const materialLabel = blox.layerLabel ? blox.layerLabel : "Unknown Material";
                        
                        if (materialLabel in previousMaterials) { 
                            previousMaterials[materialLabel] += 1
                        } else {
                            previousMaterials[materialLabel] = 1;

                            mtls.push({ 
                                materialId: blox.id, 
                                materialLabel: materialLabel,
                                isResist: false
                            });
                        }
                    }
                } else if (materialParams && hasKey(blox, materialParams.materialFieldName)) {
                    const materialId = blox.materialId ?? blox.id
                    let materialLabel:string = getProperty(blox, materialParams.materialFieldName) as string;
                    if (!materialLabel) {
                        materialLabel = materialParams.unknownLabel;
                    } 
                    if (materialLabel in previousMaterials) { 
                        previousMaterials[materialLabel] += 1
                    } else {
                        previousMaterials[materialLabel] = 1;

                        mtls.push({ 
                            materialId: materialId, 
                            materialLabel: materialLabel,
                            isResist: materialParams.isResist ?? false
                        });
                    }
                }

                if (blox.bloxType === BloxTypes.WaferBonding && blox.useBondingAgent) {
                    // special case for wafer conding bonding agent
                    let materialId = blox.materialId ?? blox.id
                    materialId += "-bonding-agent";
                    let materialLabel = blox.bondingAgentMaterial;
                    if (!materialLabel) {
                        materialLabel = "Unknown Bonding Agent";
                    } 
                    if (materialLabel in previousMaterials) { 
                        previousMaterials[materialLabel] += 1
                    } else {
                        previousMaterials[materialLabel] = 1;

                        mtls.push({ 
                            materialId: materialId, 
                            materialLabel: materialLabel,
                            isResist: false
                        });
                    }
                }
                
                return mtls;
            }, []);

            if (materialFilter === "ONLY_RESIST") {
                materials = materials.filter((material) => material.isResist);
            } else if (materialFilter === "NOT_RESIST") {
                materials = materials.filter((material) => !material.isResist);
            }
            
            // reverse
            materials.reverse();
            materialLabels = materials.map((material) => material.materialLabel);
        }

        let maxValue = 0;
        let labelStepSize = 0;
        let selectDisabled = readOnly;
        let multiSelectValue = value as string[] ?? [];
        let multiSelectPlaceholder = "Select one or more";
        const emptyLabels = "No targets found.";
                    
        let disabled = false
        const disableName = `${property}Disabled` ?? false;
        if (hasKey(selectedBlox, disableName) && typeof selectedBlox[disableName] === 'function'){
            const func = selectedBlox[disableName] as (() => boolean | null);
            disabled = func.call(selectedBlox) ?? false;
        }

        switch (fieldType) {
            case DisplayFieldTypes.Input:
                if (isNumber) {
                    return <NumericInput
                        key={`${selectedBlox.id}-${label}`}
                        id={`${selectedBlox.id}-${label}`}
                        inputValueDictState={[inputValueDict, setInputValueDict]}
                        label={label}
                        inputGroupProps={inputGroupProps}
                        onChange={(val) => onChangeValue(property, val)}
                        infoContent={infoContent}
                        disabled={disabled}
                    />
                } else {
                    return <Input
                        key={`${selectedBlox.id}-${label}`}
                        id={`${selectedBlox.id}-${label}`}
                        inputValueDictState={[inputValueDict, setInputValueDict]}
                        label={label}
                        inputGroupProps={inputGroupProps}
                        onChange={(val) => onChangeValue(property, val)}
                        infoContent={infoContent}
                        disabled={disabled}
                    />
                }
            case DisplayFieldTypes.PatternInput:
                return <CsvInput
                    key={`${selectedBlox.id}-${label}`}
                    id={`${selectedBlox.id}-${label}`}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    label={label}
                    inputGroupProps={inputGroupProps}
                    infoContent={infoContent}
                    onChange={(val) => onChangeValue(property, val)}
                />;
            case DisplayFieldTypes.Dropdown:
                return <Label key={`${selectedBlox.id}-${index}`} className="righPanelLabel">
                    {label}
                    <HTMLSelect value={value as string ?? ""} disabled={readOnly} options={options} onChange={(event) => onChangeValue(property, event.target.value)} key={`${selectedBlox.id}-${index}`}/>
                </Label>;
            case DisplayFieldTypes.MultiMaterialSelect:
                if (materialLabels.length === 0) {
                    materialLabels.push(emptyLabels);
                }
                
                if (property === "layerLabelsToGrow") {
                    // check if isotropic etch is turned on, if so, disable this field 
                    materialLabels.shift();
                    const selectiveGrowth = getValue("selectiveGrowth", selectedBlox);
                    if (selectiveGrowth) {
                        selectDisabled = false;
                    } else {
                        selectDisabled = true;
                        multiSelectValue = [];
                        multiSelectPlaceholder = "All Exposed Surfaces";
                    }   
                }
                selectDisabled = selectDisabled || disabled;
                
                //  NOTICE:
                // there is a bug in MultiSelect - if the component is under a Label - onRemove gets called twice
                return <div style={{marginBottom: 10}}>
                        <Label key={`${selectedBlox.id}-${index}`} className="righPanelLabel" style={{marginBottom: 5}}>
                            {label}
                        </Label> 
                        <MultiSelect 
                            fill={true}
                            openOnKeyDown={false}
                            selectedItems={multiSelectValue} 
                            disabled={selectDisabled}
                            items={materialLabels} 
                            itemPredicate={filterItem}
                            itemRenderer={(val,  { modifiers, handleClick }) => { 
                                if (val === emptyLabels) {
                                    return ( 
                                        <MenuItem 
                                            key={val} 
                                            text={val}
                                            disabled={true}
                                            active={false} 
                                        /> 
                                    ); 
                                } else {
                                    return ( 
                                        <MenuItem 
                                            key={val} 
                                            text={val} 
                                            onClick={handleClick} 
                                            active={multiSelectValue.includes(val)} 
                                        /> 
                                    ); 
                                }
                                
                                
                            }} 
                            onItemSelect={(val) => { 
                                // select / deselect item
                                if (multiSelectValue.includes(val)) {
                                    onChangeValue(property, multiSelectValue.filter(e => e !== val));
                                } else {
                                    onChangeValue(property, [val, ...multiSelectValue]);
                                }
                            }} 
                            onRemove={(val) => {
                                console.log("Remove ", val, " from ", multiSelectValue);
                                onChangeValue(property, multiSelectValue.filter(e => e !== val));
                            }}
                            placeholder={multiSelectPlaceholder}
                            tagRenderer={(item) => item} 
                        /> 
                    </div>;
            // For Title
            case DisplayFieldTypes.Multiline:
                return <Label key={`${selectedBlox.id}-comments`} className={"righPanelLabel rightPanelCommentsContainer"} >
                    {label}
                    <TextArea disabled={readOnly} className={"rightPanelComments"} fill={true} value={value as string ?? ""} onChange={(event) => onChangeValue(property, event.target.value)} title={value as string}  key={`${selectedBlox.id}-comments`}/>
                </Label>;
            case DisplayFieldTypes.Switch:
                {
                    return <LabeledSwitch
                        key={`${selectedBlox.id}-${label}`}
                        label = {label}
                        infoContent = {infoContent}
                        disabled = {readOnly || disabled}
                        onChange={() => onChangeValue(property, !value)}
                        checked = {value as boolean}
                    />
                }
            case DisplayFieldTypes.Color:
                return <Label key={`${selectedBlox.id}-${index}`}>
                    {label}
                    <Popover
                        className={'inputMenuPopover'}
                        content={
                            <SketchPicker
                                color={{
                                    r: (value as IColor).R,
                                    g: (value as IColor).G,
                                    b: (value as IColor).B,
                                    a: (value as IColor).A
                                }}
                                onChange={(newColor) => {
                                    onChangeValue(property, {
                                        R: newColor.rgb.r,
                                        G: newColor.rgb.g,
                                        B: newColor.rgb.b,
                                        A: newColor.rgb.a ?? 1
                                    });
                                }}
                            />
                        }
                        disabled={readOnly || disabled}
                        placement='auto'
                    >
                        <div className='colorPreview' style={{ backgroundColor: Color.getCSS(value as IColor) }} />
                    </Popover>
                </Label>   
            case DisplayFieldTypes.Slider:
                {// backward compatibility for conversion of input field to slider
                    let numVal = Number(value);
                    numVal = typeof numVal !== 'number' ? 0 : numVal;
                    maxValue = maxSliderValue ?? 50;
                    labelStepSize = maxValue - 1;

                    return <Label key={`${selectedBlox.id}-${index}`} className={"righPanelLabel"}>
                        {label}
                        <Slider
                            min={1}
                            max={maxValue}
                            stepSize={1}
                            labelStepSize={labelStepSize}
                            onChange={(val) => onChangeValue(property, val)}
                            value={numVal}
                            disabled={readOnly || disabled}
                        // vertical={false}
                        />
                    </Label>
                }
            case DisplayFieldTypes.PercentageSlider:
                {// backward compatibility for conversion of input field to slider
                    let disabled = readOnly;

                    if (property === "sidewaysEtch") {
                        // check if isotropic etch is turned on, if so, disable this field 
                        const isotropicEtch = getValue("isotropicEtch", selectedBlox);
                        if (isotropicEtch) {
                            disabled = true;
                        }
                    }

                    return  <PercentageSlider
                        key={`${selectedBlox.id}-${label}`}
                        id={`${selectedBlox.id}-${label}`}
                        label={label}
                        onChange={(val) => onChangeValue(property, val)}
                        infoContent={infoContent}
                        value={Number(value)}
                        maxSliderValue={100}
                        stepSize={1}
                        disabled={disabled}
                    />  
                }   
            default:
                return <></>;
        }
    }

    const getCustomDisplayElement = (id: string) => {
        const [label, value] = getCustomValue(id, selectedBlox);

        const rightEl = <Button minimal={true} small={true} icon={IconNames.REMOVE} onClick={() => deleteCustomField(id)} />

        const inputGroupProps: InputGroupProps2 = {
            title: "", 
            rightElement: rightEl,
            value: value as string ?? "", 
            disabled: readOnly,
            placeholder: "" 
        }
          
        return <Input
                key={id}
                id={id}
                inputValueDictState={[inputCustomValueDict, setCustomInputValueDict]}
                label={label}
                inputGroupProps={inputGroupProps}
                onChange={(val) => onChangeCustomValue(id, val)}
                onChangeLabel={(val) => onChangeCustomLabel(id, val)}
            />
    }

    //todo choose a better key then index
    return <>
        <EditableText placeholder='Click to edit Blox Name' disabled={readOnly} className={"rightPanelTitle"} value={selectedBlox.name} onChange={(val) => onChangeValue("name", val)}/>
        { selectedBlox.bloxType !== BloxTypes.StartBlox && <div style={{fontWeight: 'bold', margin: 'auto'}}>
            <EditableText placeholder='Click to edit Tool Name' value={selectedBlox.toolName ?? ""} onChange={(val) => onChangeValue("toolName", val)}/>
        </div> }
        <BloxSvg bloxType={selectedBlox.bloxType} className='rightPanelImage'/>
        <Divider/>
            <ButtonGroup className={'blox-tab-group'} fill={true} minimal={true}>
                <Button active={tab === Tab.EXPERIMENTAL} onClick={() => setTab(Tab.EXPERIMENTAL)} minimal={true}>Experimental</Button>
                <Button active={tab === Tab.DISPLAY} onClick={() => setTab(Tab.DISPLAY)} minimal={true}>Display</Button>
                <Button text='Simulate' className={`simulate-tab ${!showSimulateTab ? 'simulate-tab-hidden' : ''}`} active={tab === Tab.SEMIFAB} onClick={() => setTab(Tab.SEMIFAB)} minimal={true}/>
            </ButtonGroup>
        <Divider />        
        <div style={{'marginBottom': '6px'}}>
            {tab === Tab.SEMIFAB && <Row>
                <Tag minimal style={{marginLeft: 'auto', backgroundColor: Colors.BLUE2, color: Colors.WHITE}}>Beta</Tag>
            </Row>
                }
        </div>
        { selectedBlox && selectedBlox.bloxType === BloxTypes.StartBlox && <>
                { !readOnly && <Button 
                    onClick={() => addLayer()} 
                    minimal={false} 
                    icon={IconNames.PLUS}
                    style={{marginBottom: 15, marginTop:15}}>Add Layer</Button> 
                }
                { selectedBlox.layers && [...selectedBlox.layers].reverse().map((layer, index) => {
                    const realIndex = selectedBlox.layers ? selectedBlox.layers.length - index - 1 : 0;
                    return getLayerDisplayElement(realIndex);
                }) }
            </>
        }
        { fieldsToDisplay.map((property, index) => getDisplayElement(property, index)) }
        { tab === Tab.EXPERIMENTAL && <>
            { selectedBlox && selectedBlox.bloxType !== BloxTypes.StartBlox && <>
                { customFields.map((id, index) => getCustomDisplayElement(id)) }
                { !readOnly && <Button onClick={() => addCustomField()} minimal={false} style={{marginBottom: 15, marginTop:22}}>Add Custom Parameter</Button> }
            </>}
            { getDisplayElement("commentField")}
        </> }
    </>;
}

