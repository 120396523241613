export interface IColor {
    R: number;
    G: number;
    B: number;
    A: number;
}

export class Color {
    R: number;
    G: number;
    B: number;
    A: number;

    constructor(R: number, G: number, B: number, A = 1) {
        this.R = R;
        this.G = G;
        this.B = B;
        this.A = A;
    }

    getDarker(): Color {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);
        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.69);
        return new Color(darkerRGB[0], darkerRGB[1], darkerRGB[2], this.A)
    }

    getSlightlyDarker(): Color {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);
        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.85);
        return new Color(darkerRGB[0], darkerRGB[1], darkerRGB[2], this.A)
    }

    getCSS(): string {
        return "rgba(" + this.R + "," + this.G + "," + this.B + "," + this.A + ")";
    }
    
    getRGB(): string {
        return "rgb(" + this.R + "," + this.G + "," + this.B +  ")";
    }
    
    getA(): string {
        return String(this.A);
    }

    static getCSS(color: IColor) {
        return `rgba(${color?.R},${color?.G },${color?.B},${color?.A})`;
    }

    getJson() {
        return {r: this.R, g: this.G, b: this.B, a: this.A}
    }

    getFrontCSS(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const lighterRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.93);

        return "rgba(" + lighterRGB[0] + "," + lighterRGB[1] + "," + lighterRGB[2] + "," + this.A + ")";
    }
    
    getFrontRGB(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const lighterRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.93);

        return "rgb(" + lighterRGB[0] + "," + lighterRGB[1] + "," + lighterRGB[2] + ")";
    }

    getSideCSS(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.75);

        return "rgba(" + darkerRGB[0] + "," + darkerRGB[1] + "," + darkerRGB[2] + "," + this.A + ")";
    }

    getSideRGB(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.75);

        return "rgba(" + darkerRGB[0] + "," + darkerRGB[1] + "," + darkerRGB[2] + ")";
    }


    // utility functions
    // from: https://axonflux.com/handy-rgb-to-hsl-and-rgb-to-hsv-color-model-c
    rgbToHsv(r: number, g: number, b: number): number[] {
        r = r/255;
        g = g/255;
        b = b/255;
        const max: number = Math.max(r, g, b);
        const min: number = Math.min(r, g, b);
        let h = 0;
        const v: number = max;

        const d: number = max - min;
        const s = max === 0 ? 0 : d / max;

        if(max === min){
            h = 0; // achromatic
        }else{
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, v];
    }

    hsvToRgb(h: number, s: number, v:number):number[] {
        let r = 0;
        let g = 0;
        let b = 0;

        const i: number = Math.floor(h * 6);
        const f: number = h * 6 - i;
        const p: number = v * (1 - s);
        const q: number = v * (1 - f * s);
        const t: number = v * (1 - (1 - f) * s);

        switch(i % 6){
            case 0: r = v; g = t; b = p; break;
            case 1: r = q; g = v; b = p; break;
            case 2: r = p; g = v; b = t; break;
            case 3: r = p; g = q; b = v; break;
            case 4: r = t; g = p; b = v; break;
            case 5: r = v; g = p; b = q; break;
        }

        return [r * 255, g * 255, b * 255];
    }
}
