import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { Features } from '../components/Features'
import { WelcomeBox } from '../components/WelcomeBox'
import { TeamListing } from '../components/TeamListing'
import { EmailBox, TextBox } from '../components/TextBox'
import { Column } from '../Layout/layouts'

export const LandingPage: React.FC = () => {
  return (
    <Column className={'searchContainer'}>
      <WelcomeBox />
      <EmailBox
        title={'Contact Us'}
        description={
          'We want to hear what you and your team are looking for'
        }
        buttonTxt={'Submit Feedback'}
        link={''}
      />
      <TextBox
        title={'Check out FabuBase'}
        description={'Our peer-created public process database'}
        buttonTxt={'Search FabuBase'}
        link={'/fabubase'}
      />
     
      <Features />
      <TeamListing />
    </Column>
  )
}
