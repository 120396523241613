import { Row } from "../Layout/layouts";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";

export interface StackProps {
    bloxSVG: JSX.Element,
    renderMenu: () => JSX.Element,
}

export const Stack: React.FC<StackProps> = (props) => {
    const {bloxSVG, renderMenu} = props;

    return (
        <Row>
            <div className="bloxBottom">
                {bloxSVG}
                <div className="bloxMenuIcon">
                <Popover2 content={renderMenu()}>
                    <Icon size={10} icon={IconNames.MORE}/>
                </Popover2>
                </div>
            </div>
        </Row>

      );
}


