import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { DisplayFieldTypes, Tab, Units } from "../enums";

export interface StripResist extends BaseBlox {
    // to do user select number of cleaning steps
    chemical: string | null;
    time: number | null;
    timeUnit: Units | null;
    temp: number | null;
    tempUnit: Units | null;
    // SEMIFAB
    layerLabelsToRemove: string[] | null;
    keepFloatingStructures: boolean | null;
    layerLabelsToFloat: string[] | null;
    layerLabelsToFloatDisabled: () => boolean | null;
}

export const stripResistDisplay: DisplayMap = {
    chemical: {
        fieldType: DisplayFieldTypes.Input,
        label: "Stripping Agent(s)",
        placeholder: "Enter chemical name(s)",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
      },
    time: {
        fieldType: DisplayFieldTypes.Input,
        label: "Time",
        placeholder: "Enter strip time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    temp: {
        fieldType: 1,
        label: "Temperature",
        placeholder: "Enter strip temperature",
        isNumber: true,
        units: [Units.CELSIUS,Units.KELVIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    // SEMIFAB
    layerLabelsToRemove: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Additional Layer(s) to Remove",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: "This will fully remove the selected layers even if they are buried in addition to all resists.",
        materialFilter: "NOT_RESIST",
    },
    keepFloatingStructures: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Keep Floating Structures",
        order: 5,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: false
    },
    layerLabelsToFloat: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Float",
        order: 9,
        tabs: [Tab.SEMIFAB],
    },
}

export const getStripResist = ({stepNumber}: BloxArgs): StripResist => ({
        name: `Wet Strip Resist ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.StripResist,
        chemical: null,
        time: null,
        timeUnit: Units.MIN,
        temp: null,
        tempUnit: Units.CELSIUS,
        commentField: null,
        customFields: {},
        // semifab
        layerLabelsToRemove: [],
        layerLabelsToFloat: [],
        keepFloatingStructures: false,
        layerLabelsToFloatDisabled: function () {
            return !this.keepFloatingStructures ?? false;
        },
    }
);
