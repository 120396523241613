import React, { ReactElement, useState } from 'react';
import { Button, Dialog, InputGroup, TextArea, Intent, FormGroup } from '@blueprintjs/core';
import { useAuth0 } from '@auth0/auth0-react';

interface MailComponentProps {
    children: (
        handleOpen: () => void
    ) => ReactElement;
    messagePlaceholder?: string;
}

export const ContactDialog: React.FC<MailComponentProps> = (props) => {
    const { user } = useAuth0();
    const loggedInEmail = user?.email;
    const loggedInSub = user?.sub;
    const {messagePlaceholder} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        setIsOpen(false);
        setName('');
        setEmail('');
        setMessage('');
    }

    const handleSubmit = async () => {
        const discordWebhookURL = 'https://discord.com/api/webhooks/1151202493548736624/n_D5mStgFcly5n1L52CTY4M347K8mOU4lW22a7Maa5sqikIPmBCQI-Yy59eRWLdyADWb';

        const discordMessage = {
            content: `**New Message from:** ${name} \n**Email:** ${email}\n**Message:** ${message}`
        };

        if (loggedInEmail || loggedInSub) {
            discordMessage.content += `\n**Logged in email:** ${loggedInEmail} \n**Logged in sub:** ${loggedInSub}`;
        }

        try {
            await fetch(discordWebhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(discordMessage)
            });

            handleClose();
        } catch (error) {
            console.error('Error sending message to Discord:', error);
        }
    };

    const defaultStyle = {
        marginBottom: '10px',
    }

    return (
        <>
            {props.children(handleOpen)}

            <Dialog
                isOpen={isOpen}
                onClose={handleClose}
                title="Fabublox Feedback"
            >
                <div className="bp5-dialog-body">
                    <FormGroup>
                    <InputGroup
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={defaultStyle}
                    />
                    <InputGroup
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={defaultStyle}
                    />
                    <TextArea
                        placeholder={messagePlaceholder ?? "Your message..."}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        growVertically={true}
                        large={true}
                        fill={true}
                        style={defaultStyle}
                    />
                    </FormGroup>
                </div>
                <div className="bp5-dialog-footer">
                    <div className="bp5-dialog-footer-actions">
                        <Button text="Submit" intent={Intent.PRIMARY} onClick={handleSubmit} />
                    </div>
                </div>
            </Dialog>
        </>
    );
}