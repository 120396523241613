import { SubstrateStack } from "./substrate-stack";
import { SpinCoat } from "./spin-coat";
import { SpinCoatResist } from "./spin-coat-resist";
import { Bake } from "./bake";
import { MaskAlign } from "./mask-align";
import { Develop } from "./develop";
import { RIE } from "./rie";
import { Sputter } from "./sputter";
import { Wetetch } from "./wetetch";
import { FlipStack } from "./flipstack";
import { CustomClean } from "./customclean";
import { ALD } from "./ald";
import { EbeamEvap } from "./ebeamevap";
import { ThermalEvap } from "./thermalevap";
import { Liftoff } from "./liftoff";
import { RCAClean } from "./rcaclean";
import { DirectWriteLitho } from "./direct-write-litho";
import { HMDSVapor } from "./HMDS-vapor";
import { ICPRIE } from "./icp-rie";
import { StripResist } from "./strip-resist";
import { EbeamLitho } from "./ebeam-litho";
import { PECVD } from "./pecvd";
import { IonImplantation } from "./ion-implantation";
import { DopeDiffusion } from "./doping-diffusion";
import { IColor } from "../../utils/Color";
import { StartBlox } from "./start-blox";
import { PlasmaAsh } from "./plasma-ash";
import { PlasmaDescum } from "./plasma-descum";
import { IonMilling } from "./ion-milling";
import { DropCast } from "./drop-cast";
import { WetGrowth } from "./wet-growth";
import { InterferenceLitho } from "./interference-litho";
import { SpinDopant } from "./spin-coat-dopant";
import { WaferBonding } from "./wafer-bonding";
import { ThermalOxide } from "./thermal-oxide";
import { RTA } from "./rta";
import { CMP } from "./cmp";
import { StepperLitho } from "./stepper-litho";
import { CustomBlox } from "./customblox";
import { MOCVD } from "./mocvd";
import { MBE } from "./mbe";
import { ImprintLitho } from "./imprint-litho";
import { Units } from "../enums";
import { Electroplate } from "./electroplate";

export interface BloxArgs {
    stepNumber: number | string; // the nth spin coat in the process //hacky, revist
}

export interface BaseBlox {
    name:           string; // user configured name
    id:             string; // unique generated id --> use UUID
    bloxType:       BloxTypes; // name of blox in library, also name of icon
    toolName?:      string | null; // user set tool name
    commentField?:  string | null; // comment field created for all blox
    moduleId?: string | null; // original module id
    moduleBloxId?: string | null; // original module blox id
    moduleName?: string | null; // original module id
    customFields?:  {[fieldId: string]: [string, string]};
}

export interface LayerProperties {
    layerColor: IColor | null;
    layerThickness: number | null; // thickness of layer for StackGenerator
    layerSimulationThickness: number | null; // thickness of layer for simulation
    layerSimulationThicknessUnit: Units;
    layerLabel: string | null;
    showLabel: boolean | null;
    materialId: string;
}

export type AllBloxes = BaseBlox &
    Partial<
        SubstrateStack &
        CustomBlox &
        CustomClean &
        RCAClean &
        SpinCoat &
        SpinCoatResist &
        SpinDopant &
        Bake &
        MaskAlign &
        StepperLitho &
        DirectWriteLitho &
        InterferenceLitho &
        EbeamLitho &
        ImprintLitho &
        Develop &
        ICPRIE &
        RIE &
        CMP &
        PlasmaAsh &
        PlasmaDescum & 
        IonMilling &
        HMDSVapor &
        Sputter &
        MBE &
        MOCVD &
        ALD &
        EbeamEvap &
        ThermalEvap &
        ThermalOxide &
        Electroplate &
        Wetetch &
        Liftoff &
        StripResist &
        FlipStack &
        PECVD & 
        DropCast &
        WetGrowth &
        IonImplantation &
        DopeDiffusion &
        RTA & 
        WaferBonding &
        StartBlox
    >;

// Assigned value should match png name of blox icon
export enum BloxTypes {
    StartBlox = "STARTBLOX", // the blox that says "PROCESS START"
    SubstrateStack = "SUBSTACK",
    CustomClean = "CUSTOMCLEAN",
    RCAClean = "RCACLEAN",
    SpinCoat = "SPINCOAT",
    SpinCoatResist = "SPINCOATRESIST",
    SpinDopant = "SPINDOPANT",
    Bake = "BAKE",
    MaskAlign = "MASKALIGN",
    StepperLitho = "STEPPERLITHO",
    DirectWriteLitho = "DIRECTWRITELITHO",
    InterferenceLitho = "INTERFERENCE-LITHO",
    EbeamLitho = "EBL",
    ImprintLitho = "IMPRINTLITHO",
    Develop = "DEVELOP",
    ICPRIE = "ICPRIE",
    RIE = "RIE",
    IonMilling = "IONMILL",
    CMP = "CMP",
    PlasmaAsh = "PLASMA-ASH",
    PlasmaDescum = "PLASMA-DESCUM",
    HMDSVapor = "HMDSVAPOR",
    Sputter = "SPUTTER",
    ThermalEvap = "THERMALEVAP",
    EbeamEvap = "EBEAMEVAP",
    MBE = "MBE",
    MOCVD = "MOCVD",
    ALD = "ALD",
    PECVD = "PECVD",
    LPCVD = "LPCVD",
    APCVD = "APCVD",
    DropCast = "DROPCAST",
    WetGrowth = "WETGROWTH",
    ThermalOxide = "THERMALOXIDATION",
    Electroplate = "ELECTROPLATE",
    Wetetch = "WETETCH",
    LiftOff = "LIFTOFF",
    StripResist = "STRIPRESIST",
    FlipStack = "FLIPSTACK",
    IonImplantation = "IONIMPLANT",
    DopeDiffusion = "DOPEDIFFUSION",
    RTA = "RTA",
    WaferBonding = "WAFERBONDING",
    CustomBlox = "CUSTOMBLOX",
}

export const bloxNamesMap: { [key in BloxTypes]: string } = {
    [BloxTypes.StartBlox]: "PROCESS START",
    [BloxTypes.SubstrateStack]: "Substrate Stack",
    [BloxTypes.CustomBlox]: "Custom Step",
    [BloxTypes.CustomClean]: "Custom Clean",
    [BloxTypes.RCAClean]: "RCA Clean",
    [BloxTypes.SpinCoat]: "Spin-Coat",
    [BloxTypes.SpinCoatResist]: "Spin Resist",
    [BloxTypes.SpinDopant]: "Spin-On Dopant",
    [BloxTypes.Bake]: "Bake",
    [BloxTypes.MaskAlign]: "Mask-Align Lithography",
    [BloxTypes.StepperLitho]: "Stepper Lithography",
    [BloxTypes.DirectWriteLitho]: "Direct-Write Lithography",
    [BloxTypes.InterferenceLitho]: "Interference Lithography",
    [BloxTypes.EbeamLitho]: "Electron-Beam Lithography",
    [BloxTypes.ImprintLitho]: "Imprint Lithography",
    [BloxTypes.Develop]: "Develop",
    [BloxTypes.ICPRIE]: "ICP-RIE",
    [BloxTypes.RIE]: "RIE",
    [BloxTypes.IonMilling]: "Ion Milling",
    [BloxTypes.CMP]: "CMP",
    [BloxTypes.PlasmaAsh]: "Plasma Ashing",
    [BloxTypes.PlasmaDescum]: "Plasma Descum",
    [BloxTypes.HMDSVapor]: "HMDS Vapor Prime",
    [BloxTypes.Sputter]: "Sputter",
    [BloxTypes.ThermalEvap]: "Thermal Evaporation",
    [BloxTypes.EbeamEvap]: "E-beam Evaporation",
    [BloxTypes.MBE]: "MBE",
    [BloxTypes.MOCVD]: "MOCVD/MOVPE",
    [BloxTypes.ALD]: "ALD",
    [BloxTypes.PECVD]: "PE-CVD",
    [BloxTypes.ThermalOxide]: "Thermal Oxide Growth",
    [BloxTypes.Electroplate]: "Electroplate",
    [BloxTypes.LPCVD]: "LP-CVD",
    [BloxTypes.APCVD]: "AP-CVD",
    [BloxTypes.DropCast]: "Drop-Cast",
    [BloxTypes.WetGrowth]: "Wet Film Growth",
    [BloxTypes.Wetetch]: "Wet-Etch",
    [BloxTypes.LiftOff]: "Lift-Off",
    [BloxTypes.StripResist]: "Wet Strip Resist",
    [BloxTypes.FlipStack]: "Flip Stack",
    [BloxTypes.IonImplantation]: "Ion Implantation",
    [BloxTypes.DopeDiffusion]: "Dopant Diffusion",
    [BloxTypes.RTA]: "Rapid Thermal Annealing",
    [BloxTypes.WaferBonding]: "Wafer-Bonding",
};

export const reverseBloxTypes = {
    "STARTBLOX": "StartBlox",
    "SUBSTACK": "SubstrateStack",
    "CUSTOMBLOX":"CustomBlox",
    "CUSTOMCLEAN": "CustomClean",
    "RCACLEAN": "RCAClean",
    "SPINCOAT": "SpinCoat",
    "SPINCOATRESIST": "SpinCoatResist",
    "SPINDOPANT": "SpinDopant",
    "BAKE": "Bake",
    "MASKALIGN": "MaskAlign",
    "STEPPERLITHO": "StepperLitho",
    "DIRECTWRITELITHO": "DirectWriteLitho",
    "INTERFERENCE-LITHO": "InterferenceLitho",
    "EBL": "EbeamLitho",
    "IMPRINTLITHO":"ImprintLitho",
    "DEVELOP": "Develop",
    "ICPRIE": "ICPRIE",
    "RIE": "RIE",
    "IONMILL": "IonMilling",
    "PLASMA-ASH": "PlasmaAsh",
    "PLASMA-DESCUM": "PlasmaDescum",
    "HMDSVAPOR": "HMDSVapor",
    "SPUTTER": "Sputter",
    "THERMALEVAP": "ThermalEvap",
    "EBEAMEVAP": "EbeamEvap",
    "MBE": "MBE",
    "MOCVD": "MOCVD",
    "ALD": "ALD",
    "PECVD": "PECVD",
    "LPCVD":"LPCVD",
    "APCVD":"APCVD",
    "DROPCAST": "DropCast",
    "WETGROWTH": "WetGrowth",
    "ELECTROPLATE": "Electroplate",
    "WETETCH": "Wetetch",
    "LIFTOFF": "LiftOff",
    "STRIPRESIST": "StripResist",
    "FLIPSTACK": "FlipStack",
    "IONIMPLANT": "IonImplantation",
    "DOPEDIFFUSION": "DopeDiffusion",
    "WAFERBONDING": "WaferBonding",
    "RTA": "RTA",
    "CMP": "CMP",
    "THERMALOXIDATION" : "ThermalOxide"
}
