import { Button } from "@blueprintjs/core";

export const WelcomeBox: React.FC = () => {

  const buttonStyle = {
    marginTop: '10px',
    background: 'rgba(150, 86, 180)',
    color: 'white',
    fontSize: 'min(3vw, 20px)',
  };

  return (
    <div className={'welcome-box'}>
      <div className={'top-box'}>
        <h2>Welcome to FabuBlox!</h2>
        <p>
          FabuBlox is a design & data management tool for micro- and
          nanofabrication processes
        </p><br/>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/TYXCizU-iNs?controls=0" title="Fabublox Demo Video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <br/>
        <a href={'/process-editor/create'}>
          <Button large={true} style={buttonStyle} text={'Start Designing'} />
        </a>
      </div>
    </div>
  )
}
