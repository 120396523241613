// import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { FabuToaster, FeatureToaster } from "./components/toaster";
import { ToastProps } from "@blueprintjs/core";
import { runPolyfills } from './polyfills/run-polyfills';
import * as Sentry from "@sentry/react";
import { BASE_FABUBLOX_API_URL } from './utils/constants';

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://1f09b6b6f329387e112da49d212a1d8d@o4507105450852352.ingest.us.sentry.io/4507105453801472",
    tunnel: `${BASE_FABUBLOX_API_URL}/errorreport`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.fabublox\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

runPolyfills();

export const showToast = async (toastPayload: ToastProps) => {
  (await FabuToaster).show(toastPayload);
};

export const showFeatureToast = async (toastPayload: ToastProps) => {
  const toaster = await FeatureToaster;
  return toaster.show(toastPayload);
};

export const hideFeatureToast = async (key: string) => {
  const toaster = await FeatureToaster;
  toaster.dismiss(key);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development'
    }
  },
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (query?.meta?.errorMessage) {
        const msg = query.meta.errorMessage as string;
        showToast({
          message: msg,
          intent: "danger",
          timeout: 3000
        })
      }
    }
  })
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  // commenting out strict mode because it causes blueprintJs contextmenu to not work
  // waiting on fix: https://github.com/palantir/blueprint/pull/6720
  // issue: https://github.com/palantir/blueprint/issues/5503
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  // </React.StrictMode>
);
