import { BloxTypes } from "../Data/BloxSchema/base-blox";
import { ResistType } from "../Data/BloxSchema/spin-coat-resist";
import { StackChange } from "../Data/enums";
import { Clean, Develop, DopingDiffusion, Etch, FillDeposit, IonImplantation, Pattern, Polish, StackAction, ThermalOxidation, ThinDeposit, WaferBonding } from "../Data/stack-change";
import { Color } from "../utils/Color";
import { intersectPatterns, unionPatterns, intersectSupport, subtractPatterns, reversePattern, inflatePattern, inflatePatternWithLimits, largestNonGap } from "./PatternOps";
import { SVGDrawService } from "./SVGDraw";
import { cloneDeep } from 'lodash';

export interface SGVEngineReturnType {
    svg: JSX.Element,
    history: DrawCommand[]
}   

function compareByZ(a: DrawCommand, b: DrawCommand) {
    return b.z - a.z;
}

function compareByZandHeight(a: DrawCommand, b: DrawCommand) {
    return (b.z - b.height) - (a.z - a.height);
}

export interface DopeCommand {
    id: string,
    pattern: number[],
    depth: number,
    color: Color,
    label?: string,
    cornerRadius: number,
    flipped: boolean,
    sideDope: boolean,
    thickness: number
}

export interface MinimalDrawCommand {
    height: number,
    z: number,
    pattern?: number[],
}

export interface DrawCommand {
    type: string, // TODO: enum this
    uniqueKey: string,
    width: number,
    height: number,
    z: number,
    color: Color,
    resistType: ResistType,
    materialId?: string,
    label?: string,
    pattern: number[],
    exposedPattern? : number[],
    sidewaysPattern? : number[],
    dopeCommands? : DopeCommand[],
    developable? : string,
    isDopant? : boolean
}

const EMPTY_DC : DrawCommand = {
    type: "",
    uniqueKey: "",
    width: 0,
    height: 0,
    z: 0,
    color: new Color(0, 0, 0),
    resistType: ResistType.NONE,
    pattern: [1]
}

export enum DrawingFace {
    Front,
    Side,
    Top
}

export enum SVGDisplayMode {
    Process,
    Thumbnail,
    ExportPreview
}

export class SVGEngineService {

    drawService: SVGDrawService;
    drawCommands: DrawCommand[];
    maxHeight: number;
    threeDim: boolean;
    DEBUG_MODE: boolean;

    constructor() {
        this.threeDim = true;
        this.drawService = new SVGDrawService(this.threeDim);
        this.drawCommands = [];
        this.maxHeight = 100;
        this.DEBUG_MODE = false;
    }

    debugLog(...data: any[]) {
        if (this.DEBUG_MODE) {
            console.log(...data);
        }
    }

    setThreeDim(threeDim: boolean) {
        this.threeDim = threeDim;
    } 

    getClassName(svgDisplayMode: SVGDisplayMode): string {
        let className = "bloxSVG";
        if (svgDisplayMode === SVGDisplayMode.Thumbnail) {
            className = "thumbnailSVG";
        } else if (svgDisplayMode === SVGDisplayMode.ExportPreview) {
            className = "bloxSVGPreview";
        }
        return className;
    }

    getViewBox(threeDim: boolean, width: number, svgDisplayMode: SVGDisplayMode, stackHeight: number): string {
        if (threeDim) {
            return `-1 0 102 102`;
        } else if (svgDisplayMode === SVGDisplayMode.ExportPreview) {
            return `-1 ${this.maxHeight-stackHeight} ${width + 2} ${stackHeight+2}`
        }
        return `-1 0 ${width + 2} 102`
    }

    removeUnsupportedLayers(): void {
        const supportPatterns: { [key: number]: number[] } = {}
        supportPatterns[this.drawCommands[0].z] = [0, 1];

        // merge horizontal layers
        this.mergeAllHorizontal();

        // populate an dictionary that maps every z for the support patterns existing in that cut
        for (let i = 0; i < this.drawCommands.length; i++) {
            const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
            const supportPattern : number[] = supportPatterns[this.drawCommands[i].z] ?? [1];
            const intersect: number[] = intersectPatterns(supportPattern, currentPattern);
            

            if (intersect.length > 1) {
                // hack -> add +/- 2 to ever support Z.
                // TODO: find a better way?
                for (let d = -2; d <= 2; d++) {
                    const supportZ = this.drawCommands[i].z - this.drawCommands[i].height + d;
                    if (supportPatterns[supportZ]) {
                        supportPatterns[supportZ] = unionPatterns(supportPatterns[supportZ], currentPattern);
                    } else {
                        supportPatterns[supportZ] = currentPattern;
                    }
                }
                
            }
        }
        

        // traverse all the layers and remove those that have no support patterns
        for (let i = 0; i < this.drawCommands.length; i++) {
            const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
            const supportPattern : number[] = supportPatterns[this.drawCommands[i].z] ?? [1];
            const supportedPattern: number[] = intersectSupport(currentPattern, supportPattern);
            const supportZ = this.drawCommands[i].z - this.drawCommands[i].height;

            if (supportedPattern.length <= 1) {
                if (supportPatterns[supportZ]) {
                    supportPatterns[supportZ] = subtractPatterns(supportPatterns[supportZ], currentPattern);
                }

                this.drawCommands.splice(i, 1);
                i--;
            } else {
                this.drawCommands[i].pattern = supportedPattern;

                if (supportPatterns[supportZ]) {
                    supportPatterns[supportZ] = subtractPatterns(supportPatterns[supportZ], currentPattern);
                    supportPatterns[supportZ] = unionPatterns(supportPatterns[supportZ], supportedPattern);
                }

                // TODO: dope command
            }
        }

        const bottomZ = this.drawCommands[0].z;
        for (let i = 0; i < this.drawCommands.length; i++) {
            this.drawCommands[i].z += this.maxHeight - bottomZ;
        }
    }

    // merges horizontally 
    mergeAllHorizontal() {
        const materialIds: Set<string> = new Set();
        this.drawCommands.forEach((dc) => {
            const materialId = dc.materialId;
            if (materialId) {
                materialIds.add(materialId);
            }
        });
        materialIds.forEach((materialId) => {
            this.mergeHorizontal(materialId);
        })
    }

    // generate gaps per Z slice
    generateGapsPerZSlice(width: number, inflate: number): { [z: number]: number[] } {
        // sort from the top
        this.drawCommands.sort(compareByZandHeight);

        // populate an dictionary that maps every top of a slice to its pattern
        const topPatterns: { [z: number]: number[] } = {}
        const topZs : number[] = []
        for (let i = this.drawCommands.length - 1; i >= 0; i--) {
            const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
            const topZ = this.drawCommands[i].z - this.drawCommands[i].height;
            const topPattern : number[] = topPatterns[topZ] ?? [1];
            topPatterns[topZ] = unionPatterns(topPattern, currentPattern);

            if (!topZs.includes(topZ)) {
                topZs.push(topZ);
            }
        }

        // for every Z, go over any layer that is intersecting it, and subtract it from the gap pattern
        // if there is a gap pattern exists in that Z, save it
        const gapPatterns: { [z: number]: number[] } = {}
        for (let k = 0; k < topZs.length; k++) {
            let gapPattern: number[] = [0, 1];
            for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                if (this.drawCommands[i].z - this.drawCommands[i].height > topZs[k]) {
                    break;
                }
                if (this.drawCommands[i].z <= topZs[k]) {
                    continue;
                }
                
                const currentPattern : number[] = this.drawCommands[i].pattern ?? [0];
                gapPattern = subtractPatterns(gapPattern, currentPattern);

                if (gapPattern.length <= 1) {
                    break;
                }
            }
            if (gapPattern.length > 1) {
                const invertedPattern = reversePattern(gapPattern);
                const inflatedPattern = inflatePattern(invertedPattern, width, inflate);
                const reInvertPattern = reversePattern(inflatedPattern);
                if (reInvertPattern.length > 1) {
                    gapPatterns[topZs[k]] = reInvertPattern;
                }
            }
        }
        return gapPatterns;
    }

    // traverses all layers and merges all layers with the same materialId if the share a horizontal
    // assumes the list is sroted by Z
    // returns largest relative width
    mergeHorizontal(materialTarget : string): number {
        let maxRelativeWidth = 0;
        const materialLayers: number[] = [];
        for (let i = this.drawCommands.length-1; i >= 0; i--) {
            if (this.drawCommands[i].materialId === materialTarget) {
                materialLayers.push(i);
            }
        }

        if (this.DEBUG_MODE) {
            this.debugLog("Starting mergeHorizontal for ", materialTarget);
            this.debugLog("Layers: ");
            for (let i = 0; i < materialLayers.length; i++) {
                const dc = this.drawCommands[materialLayers[i]];
                this.debugLog(i, "z", dc.z, "height", dc.height, "pattern", dc.pattern);
            }
        }

        for (let i = 0; i < materialLayers.length-1; i++) {
            for (let j = i+1; j < materialLayers.length; j++) {
                const dc1 = this.drawCommands[materialLayers[i]];
                const dc2 = this.drawCommands[materialLayers[j]];

                const pattern1 = dc1.pattern ?? [1];
                const pattern2 = dc2.pattern ?? [1];

                const dpc1 = dc1.dopeCommands ?? [];
                const dpc2 = dc2.dopeCommands ?? [];

                this.debugLog("Checking ", i, " and ", j);

                if (dc1.z === dc2.z && dc1.height === dc2.height) {
                    this.debugLog("Perfect intersection");

                    // merge it all
                    const newPattern = unionPatterns(pattern1, pattern2);
                    this.drawCommands.splice(materialLayers[i], 1);
                    this.drawCommands[materialLayers[j]].pattern = newPattern;
                    this.drawCommands[materialLayers[j]].dopeCommands = [...dpc1, ...dpc2]
                    break;
                } else if (dc1.z <= (dc2.z - dc2.height) ||  dc2.z <= (dc1.z - dc1.height)) {
                    // no intersection, do nothing
                    this.debugLog("No intersection");
                } else if (dc1.z === dc2.z) {
                    // same start, diff heights
                    this.debugLog("Same start, different heights");

                    const newPattern = unionPatterns(pattern1, pattern2);
                    if (dc1.height > dc2.height) {
                        this.drawCommands[materialLayers[j]].pattern = newPattern;
                        this.drawCommands[materialLayers[i]].z -= this.drawCommands[materialLayers[j]].height;
                        this.drawCommands[materialLayers[i]].height -= this.drawCommands[materialLayers[j]].height;
                        
                        // TODO: dope commands
                    } else {
                        this.drawCommands[materialLayers[i]].pattern = newPattern;
                        this.drawCommands[materialLayers[j]].z -= this.drawCommands[materialLayers[i]].height;
                        this.drawCommands[materialLayers[j]].height -= this.drawCommands[materialLayers[i]].height;

                        // TODO: dope commands
                    }
                } else if (dc1.z - dc1.height === dc2.z - dc2.height) {
                    // different Zs, same top
                    this.debugLog("Different Zs, same top");

                    const newPattern = unionPatterns(pattern1, pattern2);
                    if (dc1.z > dc2.z) {
                        this.drawCommands[materialLayers[j]].pattern = newPattern;
                        this.drawCommands[materialLayers[i]].height -= this.drawCommands[materialLayers[j]].height;

                        // TODO: dope commands
                    } else {
                        this.drawCommands[materialLayers[i]].pattern = newPattern;
                        this.drawCommands[materialLayers[j]].height -= this.drawCommands[materialLayers[i]].height;

                        // TODO: dope commands
                    }
                } else {
                    this.debugLog("Different Zs, different top");

                    const intersect = intersectPatterns(pattern1, pattern2);

                    const w1 = largestNonGap(pattern1);
                    const w2 = largestNonGap(pattern2);
                    const wIntersect = largestNonGap(intersect);

                    if ((w1 + w2 - wIntersect) > maxRelativeWidth) {
                        maxRelativeWidth = w1 + w2 - wIntersect;
                    }
                    const bottomZ = this.drawCommands[materialLayers[j]].z - this.drawCommands[materialLayers[j]].height;
                    const topZ = this.drawCommands[materialLayers[i]].z;
                    const diff = topZ - bottomZ;

                    if (diff > 0) {
                        if (intersect.length > 1) {
                            this.debugLog("Patterns are intersecting");

                            const subtract1 = subtractPatterns(pattern1, intersect);
                            const subtract2 = subtractPatterns(pattern2, intersect);                         

                            if (subtract1.length === 1) {
                                this.drawCommands[materialLayers[i]].z -= diff;
                                this.drawCommands[materialLayers[i]].height -= diff;
                            } else if (subtract2.length === 1) {
                                this.drawCommands[materialLayers[j]].height -= diff;
                            } else {
                                const union = unionPatterns(pattern1, pattern2);

                                const newDrawCommand = Object.assign({}, dc1);

                                newDrawCommand.uniqueKey += `-split-${newDrawCommand.z-diff}`;
                                newDrawCommand.height -= diff;
                                newDrawCommand.z -= diff;
                                // TODO: alter dopeCommands?

                                // add new layer "on top"
                                this.drawCommands.splice(materialLayers[i]+1, 0, newDrawCommand);

                                // the middle layer becomes the union and its height is the diff
                                this.drawCommands[materialLayers[i]].pattern = union;
                                this.drawCommands[materialLayers[i]].height = diff;

                                // bottom layer gets a bit shorter
                                this.drawCommands[materialLayers[j]].height -= diff;
                            }
                        } else {
                            this.debugLog("Patterns are not intersecting, diff:", diff);

                            const union = unionPatterns(pattern1, pattern2);

                            const top1 = this.drawCommands[materialLayers[i]].z - this.drawCommands[materialLayers[i]].height;
                            const top2 = this.drawCommands[materialLayers[j]].z - this.drawCommands[materialLayers[j]].height;

                            if (top1 < top2) {
                                //       ___
                                //      |   |
                                //    __|   |__
                                //   |  |   |  |
                                //   |  |___|  |
                                //   |__|   |__|

                                this.debugLog("Layer ", i, " top (", top1, ") is taller than layer ", j, " (", top2, ")");

                                const newDrawCommand = Object.assign({}, dc1);

                                newDrawCommand.height = top2 - top1;
                                newDrawCommand.z = top2;
                                newDrawCommand.uniqueKey += `-split-${top1}-${top2}`;

                                this.debugLog("newDrawCommand: z", newDrawCommand.z, "height", newDrawCommand.height, "pattern", newDrawCommand.pattern);
                                // TODO: alter dopeCommands?

                                // add new layer "on top"
                                this.drawCommands.splice(materialLayers[i]+1, 0, newDrawCommand);

                                // the middle layer becomes the union and its height is the diff
                                this.drawCommands[materialLayers[i]].pattern = union;
                                this.drawCommands[materialLayers[i]].height = this.drawCommands[materialLayers[i]].z - top2;

                                // bottom layer gets a bit shorter
                                this.drawCommands[materialLayers[j]].height = this.drawCommands[materialLayers[j]].z - this.drawCommands[materialLayers[i]].z;
                            } else {   
                                //    __     __
                                //   |  |___|  |
                                //   |  |___|  |
                                //   |__|   |__|

                                this.debugLog("Layer ", i, " top (", top1, ") is shorter than layer ", j, " (", top2, ")");

                                const newDrawCommand = Object.assign({}, dc2);

                                newDrawCommand.height = top1 - top2;
                                newDrawCommand.z = top1;
                                newDrawCommand.uniqueKey += `-split-${top1}-${top2}`;

                                this.debugLog("newDrawCommand: z", newDrawCommand.z, "height", newDrawCommand.height, "pattern", newDrawCommand.pattern);
                                // TODO: alter dopeCommands?

                                // add new layer "on top"
                                this.drawCommands.splice(materialLayers[i]+1, 0, newDrawCommand);

                                // the middle layer becomes the union 
                                this.drawCommands[materialLayers[i]].pattern = union;

                                // bottom layer gets a bit shorter
                                this.drawCommands[materialLayers[j]].height = this.drawCommands[materialLayers[j]].z - this.drawCommands[materialLayers[i]].z;
                            }
                        }
                    }
                }
            }
        }

        for (let i = this.drawCommands.length-1; i >= 0; i--) {
            if (this.drawCommands[i].materialId === materialTarget) {
        
                const p = this.drawCommands[i].pattern ?? [0];
                const w = largestNonGap(p);

                if (w > maxRelativeWidth) {
                    maxRelativeWidth = w;
                }
            }
        }

        return maxRelativeWidth;
    }

    checkAndEtchIntersections(stackAction: StackAction, materialTargets: string[]) {
        const thermalOxidation = stackAction as ThermalOxidation;

        for (let i = this.drawCommands.length - 1; i >= 0; i--) {
            const dcOxidized: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;

            if (dcOxidized.materialId === thermalOxidation.materialId) {
                for (let j = this.drawCommands.length - 1; j >= 0; j--) {
                    const dcMaterial: DrawCommand = this.drawCommands[j] ?? EMPTY_DC;

                    if (dcMaterial.materialId && materialTargets.includes(dcMaterial.materialId)) {
                        if (dcOxidized.z > dcMaterial.z - dcMaterial.height && dcOxidized.z - dcOxidized.height < dcMaterial.z) {
                            const oxidizedPattern : number[] = dcOxidized.pattern ?? [1];
                            const targetPattern : number[] = dcMaterial.pattern ?? [0];
                            const intersectPattern = intersectPatterns(oxidizedPattern, targetPattern);

                            if (intersectPattern.length > 1) {
                                //console.log("Etching ", j, ", ", dcMaterial.z, ", ", dcMaterial.height, ", ", dcMaterial.pattern, " with ", intersectPattern, " until z ", dcOxidized.z);
                                this.etchOneLayerVertically(dcMaterial, j, targetPattern, intersectPattern, dcOxidized.z, `${stackAction.id}-ox-${i}-${j}`);
                            }
                        }
                    }
                }
            }
        }
    }

    // check all oxidized layers vs. all non material target layers and etch the oxidized layer
    checkAndRemoveIntersections(stackAction: StackAction, materialTargets: string[], width: number) {
        const thermalOxidation = stackAction as ThermalOxidation;

        for (let j = this.drawCommands.length - 1; j >= 0; j--) {
            const dcMaterial: DrawCommand = this.drawCommands[j] ?? EMPTY_DC;

            if (!(dcMaterial.materialId && materialTargets.includes(dcMaterial.materialId)) && dcMaterial.materialId !== thermalOxidation.materialId) {
            
                for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                    const dcOxidized: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;

                    if (dcOxidized.materialId === thermalOxidation.materialId) {
                        const oxidizedPattern : number[] = dcOxidized.pattern ?? [1];
                        const materialPattern : number[] = dcMaterial.pattern ?? [0];
                        const intersectPattern = intersectPatterns(oxidizedPattern, materialPattern);

                        if (intersectPattern.length > 1) {
                            if (dcMaterial.z - dcMaterial.height <= dcOxidized.z - dcOxidized.height && dcMaterial.z > dcOxidized.z - dcOxidized.height) {
                                // the intersecting material is "above" the oxidized layer, so we can use our etch function to etch it
                                this.etchOneLayerVertically(dcOxidized, i, oxidizedPattern, intersectPattern, dcMaterial.z, `${stackAction.id}-oxchew-${i}-${j}`);
                            } else if (dcMaterial.z - dcMaterial.height > dcOxidized.z - dcOxidized.height && dcMaterial.z < dcOxidized.z) {
                                // the intersecting material is in the middle of the oxidized material
                                this.etchOneLayerVertically(dcOxidized, i, oxidizedPattern, intersectPattern, dcMaterial.z, `${stackAction.id}-oxchew-${i}-${j}`);

                                const cutHeight = (dcMaterial.z - dcMaterial.height) - (dcOxidized.z - dcOxidized.height);
                                this.drawCommands.splice(i+1, 0, {
                                    type: "Pattern",
                                    uniqueKey: `${stackAction.id}-oxidized-cut-above-${i}-${dcMaterial.z - dcMaterial.height}`,
                                    width: width,
                                    height: cutHeight,
                                    z: dcMaterial.z - dcMaterial.height,
                                    color: thermalOxidation.color,
                                    pattern: intersectPattern,
                                    resistType:  ResistType.NONE,
                                    materialId: thermalOxidation.materialId
                                });

                            } else if (dcMaterial.z - dcMaterial.height > dcOxidized.z - dcOxidized.height && dcMaterial.z - dcMaterial.height < dcOxidized.z) {
                                // the intersecting material is "below" the oxidized material, so we need to etch from below
                                const newZ = dcMaterial.z - dcMaterial.height;
                                const oldZ = this.drawCommands[i].z;
                                const cutHeight = dcOxidized.z - newZ;
                                this.drawCommands[i].height -= cutHeight;
                                this.drawCommands[i].z = newZ;
                                
                                const subtract = subtractPatterns(oxidizedPattern, materialPattern);
                                if (subtract.length > 1) {
                                    this.drawCommands.splice(i+1, 0, {
                                        type: "Pattern",
                                        uniqueKey: `${stackAction.id}-oxidized-cut-below-${i}`,
                                        width: width,
                                        height: cutHeight,
                                        z: oldZ,
                                        color: thermalOxidation.color,
                                        pattern: subtract,
                                        resistType:  ResistType.NONE,
                                        materialId: thermalOxidation.materialId
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // traverses all layers and merges all layers with the same materialId if the share a vertical
    mergeVertical(stackAction: StackAction,  materialTarget : string) {
        const materialLayers: number[] = [];
        for (let i = this.drawCommands.length-1; i >= 0; i--) {
            if (this.drawCommands[i].materialId === materialTarget) {
                if (this.drawCommands[i].height > 0) {
                    materialLayers.push(i);

                    this.debugLog("Layer ", i, " - z:", this.drawCommands[i].z, ", height:", this.drawCommands[i].height);
                } else {
                    // remove heightless layers
                    this.drawCommands[i].pattern = [1];
                }
            }
        }
        for (let i = 0; i < materialLayers.length-1; i++) {
            for (let j = i+1; j < materialLayers.length; j++) {
                //this.debugLog("Examining ", materialLayers[i], " and ", materialLayers[j]);
                
                const dcTop = this.drawCommands[materialLayers[i]];
                const dcBottom = this.drawCommands[materialLayers[j]];

                const patternBottom = dcBottom.pattern ?? [1];
                const patternTop = dcTop.pattern ?? [1];


                if (dcBottom.z - dcBottom.height === dcTop.z) {
                    const topSubtract = subtractPatterns(patternTop, patternBottom);
                    const intersect = intersectPatterns(patternBottom, patternTop);
                    const bottomSubtract = subtractPatterns(patternBottom, patternTop);

                    if (intersect.length <= 1) {
                        // No intersection, keep layers as is
                        //this.debugLog("No intersection");
                    } else if (topSubtract.length <= 1 && bottomSubtract.length <= 1) {
                        // this.debugLog("Perfect intersection");
                        // this.debugLog("dcTop: ", dcTop.z, " , ", dcTop.height);
                        // this.debugLog("dcBottom: ", dcBottom.z, " , ", dcBottom.height);

                        // Perfect intersection - join to one drawCommand

                        this.drawCommands[materialLayers[j]].height = dcBottom.height + dcTop.height;
                        this.drawCommands[materialLayers[j]].z = dcBottom.z;

                        // TODO: merge to dope command

                        this.drawCommands[materialLayers[i]].pattern = [1];

                        break;
                    } else if (topSubtract.length <= 1) {
                        //this.debugLog("Bottom > top");
                        
                        // deal with dope commands
                        const newDopeCommandsTop = dcTop.dopeCommands?.map((dopeCommand) => {
                            dopeCommand.pattern = intersectPatterns(dopeCommand.pattern, intersect);
                            if (dopeCommand.depth >= dcTop.height) {
                                dopeCommand.depth = dopeCommand.thickness;
                            }
                            return dopeCommand;
                        });

                        const newDopeCommandsBottom = dcBottom.dopeCommands?.filter((dpc) => {
                            const newPattern = intersectPatterns(dpc.pattern, bottomSubtract);
                            return (newPattern.length > 1);
                        }).map((dpc) => {
                            dpc.pattern = intersectPatterns(dpc.pattern, bottomSubtract);
                            return dpc;
                        });

                        this.drawCommands[materialLayers[i]].dopeCommands = newDopeCommandsTop;
                        this.drawCommands[materialLayers[i]].pattern = intersect;
                        this.drawCommands[materialLayers[i]].height = dcBottom.height + dcTop.height;
                        this.drawCommands[materialLayers[i]].z = dcBottom.z;

                        this.drawCommands[materialLayers[j]].pattern = bottomSubtract;
                        this.drawCommands[materialLayers[j]].dopeCommands = newDopeCommandsBottom;

                        // TODO: merge to dope command

                    } else if (bottomSubtract.length <= 1) {
                        //this.debugLog("Top > bottom");

                        this.drawCommands[materialLayers[i]].pattern = topSubtract;

                        this.drawCommands[materialLayers[j]].pattern = intersect;
                        this.drawCommands[materialLayers[j]].height = dcBottom.height + dcTop.height;
                        this.drawCommands[materialLayers[j]].z = dcBottom.z;

                       // TODO: merge to dope command

                    } else {
                        //this.debugLog("Top and Bottom have partial intersection - create new layer");

                        const newDrawCommand = Object.assign({}, dcTop);

                        newDrawCommand.uniqueKey = `${stackAction.id}-pattern-merged-${materialLayers[i]+1}`;
                        newDrawCommand.pattern = intersect;
                        newDrawCommand.height=dcBottom.height + dcTop.height;
                        newDrawCommand.z=dcBottom.z
                        newDrawCommand.dopeCommands = []; // TODO

                        this.drawCommands.splice(materialLayers[i]+1, 0, newDrawCommand)

                        this.drawCommands[materialLayers[j]].pattern = bottomSubtract;
                        this.drawCommands[materialLayers[i]].pattern = topSubtract;
                    }
                }
            }
        }

        // remove all layers with empty pattern
        for (let i = this.drawCommands.length-1; i >= 0; i--) {
            if (this.drawCommands[i].materialId === materialTarget) {
                const p = this.drawCommands[i].pattern ?? [1];
                if (p.length <= 1) {
                    this.drawCommands.splice(i, 1);
                }
            }
        }
    }

    // always call mergeVertical before this!!
    calculateMaxHeight(materialTargets: string[]) {
        let maxHeight = 0
        const auxDrawCommands: MinimalDrawCommand[] = [];

        if (materialTargets.length === 1) {
            // in case of one target - just look for the talle 
            const materialTarget = materialTargets[0];
            for (let i = this.drawCommands.length-1; i >= 0; i--) {
                if (this.drawCommands[i].materialId === materialTarget) {
                    if (this.drawCommands[i].height > maxHeight) {
                        maxHeight = this.drawCommands[i].height;
                    }
                }
            }
        } else {
            const materialLayers: number[] = [];
            for (let i = this.drawCommands.length-1; i >= 0; i--) {
                const materialId = this.drawCommands[i].materialId
                if (materialId && materialTargets.includes(materialId)) {
                    if (this.drawCommands[i].height > 0) {
                        materialLayers.push(i);
                        auxDrawCommands.push({
                            height: this.drawCommands[i].height,
                            z: this.drawCommands[i].z,
                            pattern: this.drawCommands[i].pattern
                        });
                    } 
                }
            }
            for (let i = 0; i < auxDrawCommands.length-1; i++) {
                for (let j = i+1; j < auxDrawCommands.length; j++) {
                    const dcTop = auxDrawCommands[i];
                    const dcBottom = auxDrawCommands[j];
    
                    const patternBottom = dcBottom.pattern ?? [1];
                    const patternTop = dcTop.pattern ?? [1];
    
                    if (dcBottom.z - dcBottom.height === dcTop.z) {
                        const topSubtract = subtractPatterns(patternTop, patternBottom);
                        const intersect = intersectPatterns(patternBottom, patternTop);
                        const bottomSubtract = subtractPatterns(patternBottom, patternTop);
    
                        if (intersect.length <= 1) {
                            // No intersection, keep layers as is

                        } else if (topSubtract.length <= 1 && bottomSubtract.length <= 1) {
                            // Perfect intersection - join to one drawCommand

                            auxDrawCommands[j].height = dcBottom.height + dcTop.height;
                            auxDrawCommands[j].z = dcBottom.z;
                            auxDrawCommands[i].pattern = [1];

                        } else if (topSubtract.length <= 1) {
                            // top is contained in bottom

                            auxDrawCommands[i].pattern = intersect;
                            auxDrawCommands[i].height = dcBottom.height + dcTop.height;
                            auxDrawCommands[i].z = dcBottom.z;

                            auxDrawCommands[j].pattern = bottomSubtract;
                        } else if (bottomSubtract.length <= 1) {
                            // bottom is contained in top

                            auxDrawCommands[i].pattern = topSubtract;

                            auxDrawCommands[j].pattern = intersect;
                            auxDrawCommands[j].height = dcBottom.height + dcTop.height;
                            auxDrawCommands[j].z = dcBottom.z;
    
                        } else {
                            // tetris mode
                            const newDrawCommand = Object.assign({}, dcTop);

                            newDrawCommand.pattern = intersect;
                            newDrawCommand.height=dcBottom.height + dcTop.height;
                            newDrawCommand.z=dcBottom.z

                            auxDrawCommands.splice(j+1, 0, newDrawCommand)

                            auxDrawCommands[j].pattern = bottomSubtract;
                            auxDrawCommands[i].pattern = topSubtract;
                        }
                    }
                }
            }

            for (let i = 0; i < auxDrawCommands.length; i++) {
                if (auxDrawCommands[i].height > maxHeight) {
                    maxHeight = auxDrawCommands[i].height;
                }
            }
        }
        
        return maxHeight;
    }

    etchOneLayerVertically(dc: DrawCommand, i: number, currentPattern: number[], etchPattern: number[], etchZ: number, id: string) {
        const invertedPattern = subtractPatterns(currentPattern, etchPattern);

        if (invertedPattern.length <= 1) {
            // nothing is protected, no need for sidewall etching
            if (etchZ >= dc.z) {
                // etch everything
                this.drawCommands.splice(i, 1);
                return -1;
            } else if (etchZ >= dc.z - dc.height) {
                // partial etch everying
                const newDrawCommand = Object.assign({}, dc);
                newDrawCommand.height = dc.z - etchZ;

                const etchDepth = dc.height - (dc.z - etchZ);
                const newDopeCommands = dc.dopeCommands?.filter((dpc) => {
                    if (!dpc.flipped) {
                        return (dpc.depth - etchDepth > 0);
                    } else {
                        return true;
                    }
                }).map((dpc) => {
                    const newDPC = Object.assign({}, dpc)
                    if (!dpc.flipped) {
                        newDPC.depth = newDPC.depth - etchDepth;
                    } else {
                        if (dc.height - etchDepth < dpc.depth) {
                            newDPC.depth = dc.height - etchDepth;
                        }
                    }
                    return newDPC;
                });
                newDrawCommand.dopeCommands = newDopeCommands;
                newDrawCommand.uniqueKey = dc.uniqueKey + `-partial-etched-${etchZ}`;
                this.drawCommands[i] = newDrawCommand;
                return 0;
            }
        } else {
            // some part of the layer is protected
            if (etchZ >= dc.z) {
                if (invertedPattern.length <= 1) {
                    this.drawCommands.splice(i, 1);
                    return -1;
                } else {
                
                    const newDrawCommand = Object.assign({}, dc);

                    // replace the entire layer with the protected pattern
                    const newDopeCommands = dc.dopeCommands?.map((dopeCommand, index) => {
                        dopeCommand.pattern = intersectPatterns(dopeCommand.pattern, invertedPattern);
                        return dopeCommand;
                    });

                    newDrawCommand.uniqueKey = `${id}-pattern-etched-${i}`;
                    newDrawCommand.pattern = invertedPattern;
                    newDrawCommand.dopeCommands = newDopeCommands;

                    this.drawCommands[i] = newDrawCommand;
                    return 0;
                }
            } else if (etchZ >= dc.z - dc.height) {
                // split layer into two draw commands, pattern and non
                const newDrawCommandTop = Object.assign({}, dc);
                const newDrawCommandBottom = Object.assign({}, dc);

                const etchDepth = dc.height - (dc.z - etchZ);

                const newDopeCommandsTop = dc.dopeCommands?.map((dopeCommand, index) => {
                    const newDPC: DopeCommand = Object.assign({}, dopeCommand)
                    newDPC.pattern = intersectPatterns(dopeCommand.pattern, invertedPattern);
                    if (etchDepth <= newDPC.depth) {
                        newDPC.depth = etchDepth;
                        newDPC.cornerRadius = 0;
                    }
                    return newDPC;
                });

                const topHeight = dc.height - (dc.z - etchZ);
                newDrawCommandTop.uniqueKey = `${id}-pattern-etched-top-${etchZ}-${topHeight}-${i}`;
                newDrawCommandTop.z = etchZ;
                newDrawCommandTop.height = topHeight;
                newDrawCommandTop.pattern = invertedPattern;
                newDrawCommandTop.dopeCommands = newDopeCommandsTop;

                const newDopeCommandsBottom = dc.dopeCommands?.filter((dpc) => {
                    return (dpc.depth - etchDepth > 0);
                }).map((dpc) => {
                    const newDPC = Object.assign({}, dpc)
                    newDPC.depth = newDPC.depth - etchDepth;
                    return newDPC;

                });

                newDrawCommandBottom.uniqueKey = `${id}-pattern-etched-bottom-${etchZ}-${i}`;
                newDrawCommandBottom.height = dc.z - etchZ; 
                newDrawCommandBottom.dopeCommands = newDopeCommandsBottom;

                if (invertedPattern.length <= 1) {
                    this.drawCommands[i] = newDrawCommandBottom;
                    return 0;
                } else {
                    this.drawCommands[i] = newDrawCommandTop;
                    this.drawCommands.splice(i, 0, newDrawCommandBottom)
                    return 1;
                }
            }
        }
    }

    // this function can throw errors
    generateSVG(stackActions: StackAction[], previousDrawCommands: DrawCommand[], threeDim: boolean, svgDisplayMode: SVGDisplayMode): SGVEngineReturnType {
        this.threeDim = threeDim;
        this.drawService.setThreeDim(threeDim);
        this.drawService.setThumbnailMode(svgDisplayMode === SVGDisplayMode.Thumbnail);
        this.drawService.setExportPreviewMode(svgDisplayMode === SVGDisplayMode.ExportPreview);
        this.drawService.setEpsilon(svgDisplayMode === SVGDisplayMode.ExportPreview ? 0.1 : 0.2);
        const width: number = this.threeDim ? 72 : 100;
        this.drawCommands = previousDrawCommands;

        for (const stackAction of stackActions) {

            this.DEBUG_MODE = false; // stackAction.id.startsWith("a65313a3-d775-488e-961a-af8f63cdb904");

            if (stackAction.type === StackChange.FillDeposit) {
                const fillDeposit = stackAction as FillDeposit;

                this.drawCommands.sort(compareByZandHeight);

                if (this.drawCommands.length === 0) {
                    this.drawCommands.push({
                        type: "Pattern",
                        uniqueKey: `${stackAction.id}-sqbox`,
                        width: width,
                        height: fillDeposit.thickness,
                        z: this.maxHeight,
                        color: fillDeposit.color,
                        label: fillDeposit.label,
                        pattern: [0, 1],
                        resistType: fillDeposit.resistType,
                        materialId: fillDeposit.materialId,
                        isDopant: fillDeposit.isDopant
                    });
                } else {
                    let dynamicPattern : number[] = [0, 1, 0]; // start with a whole square
                    let lastZ = this.drawCommands[this.drawCommands.length - 1].z - this.drawCommands[this.drawCommands.length - 1].height - fillDeposit.thickness;
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
                        const currentZ = this.drawCommands[i].z - this.drawCommands[i].height;
                        const sidwaysPattern = this.drawCommands[i].sidewaysPattern ?? [0];

                        if (lastZ < currentZ) {
                            const landingPattern: number[] = intersectPatterns(dynamicPattern, currentPattern);

                            if (landingPattern.length > 1 || sidwaysPattern.length > 1) {
                                this.drawCommands.splice(i + 1, 0, {
                                    type: "Pattern",
                                    uniqueKey: `${stackAction.id}-pattern-deposited-${i}`,
                                    width: width,
                                    height: currentZ - lastZ,
                                    z: currentZ,
                                    color: fillDeposit.color,
                                    label: fillDeposit.label,
                                    pattern: dynamicPattern,
                                    resistType: fillDeposit.resistType,
                                    materialId: fillDeposit.materialId,
                                    isDopant: fillDeposit.isDopant
                                });
    
                                lastZ = currentZ;
                            } 
                            
                            if (sidwaysPattern.length > 1) {
                                // special case for layers that are sideways etched
                                dynamicPattern = unionPatterns(dynamicPattern, sidwaysPattern);
                            }
                        }

                        dynamicPattern = subtractPatterns(dynamicPattern, currentPattern);

                        if (dynamicPattern.length <= 1) {
                            break;
                        }
                    }
                }
            } else if (stackAction.type === StackChange.WaferBonding) {
                const waferBonding = stackAction as WaferBonding;

                this.drawCommands.sort(compareByZandHeight);
                let lastZ = this.maxHeight;
                if (this.drawCommands.length > 0) {
                    lastZ = this.drawCommands[this.drawCommands.length - 1].z - this.drawCommands[this.drawCommands.length - 1].height;
                }
                
                this.drawCommands.push({
                    type: "Pattern",
                    uniqueKey: `${stackAction.id}-wafer`,
                    width: width,
                    height: waferBonding.thickness,
                    z: lastZ,
                    color: waferBonding.color,
                    label: waferBonding.label,
                    pattern: [0, 1],
                    resistType: ResistType.NONE,
                    materialId: waferBonding.materialId
                });
            } else if (stackAction.type === StackChange.ThinDeposit) {
                const thinDeposit = stackAction as ThinDeposit;

                // merge horizontal layers
                this.mergeAllHorizontal();
                this.drawCommands.sort(compareByZandHeight);

                let gapsByZ: { [z: number]: number[] } = {}
                if (thinDeposit.thicknessSide > 0) {
                    gapsByZ = this.generateGapsPerZSlice(width, thinDeposit.thicknessSide);
                }

                if (!thinDeposit.selectiveGrowth || !thinDeposit.materialTargets) {
                    // regular thin deposit

                    this.debugLog("----- START THIN DEPOSIT ------");

                    let dynamicPattern : number[] = [0, 1, 0]; // start with a whole square
                    let inflatedPattern : number[] = [0, 1, 0]; 
                    let antiInflatePattern : number[] = [1]; // this pattern holds the union of all the intersects to make sure we dont overlap pillars
                    let pillarPattern : number[] = [1];
                    let i = this.drawCommands.length - 1;
                    let lastPillarZ = this.drawCommands[i].z - this.drawCommands[i].height;
                    let lastZ = this.drawCommands[i].z - this.drawCommands[i].height;
                    for (; i >= 0; i--) {
                        const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
                        const currentZ = this.drawCommands[i].z - this.drawCommands[i].height;

                        if (thinDeposit.thicknessSide > 0) {
                            // first, check if we moved to a new Z, and if so, prune pillars that have tocuhed gaps
                            if (lastZ < currentZ && gapsByZ[lastZ]) {
                                pillarPattern = subtractPatterns(pillarPattern, gapsByZ[lastZ]);
                            }
                            lastZ = currentZ;

                            // second, draw pillar(s) from the previous layer to this one, if exists
                            if (pillarPattern.length > 1) {
                                const pillarZ = this.drawCommands[i].z - this.drawCommands[i].height;
                                const pillarHeight = pillarZ - lastPillarZ + thinDeposit.thicknessTop;

                                if (pillarHeight > 0) {
                                    this.drawCommands.splice(i + 1, 0, {
                                        type: "Pattern",
                                        uniqueKey: `${stackAction.id}-pattern-pillar-${i}`,
                                        width: width,
                                        height: pillarHeight,
                                        z: pillarZ,
                                        color: thinDeposit.color,
                                        pattern: pillarPattern,
                                        resistType:  thinDeposit.resistType,
                                        materialId: thinDeposit.materialId
                                    });
            
                                    lastPillarZ = pillarZ;
                                }
                            }

                            // third, check if the current layer intersects with one of the pillars, and if so remove that pillar
                            const intersectPillars = intersectPatterns(pillarPattern, currentPattern);
                            if (intersectPillars.length > 1) {
                                pillarPattern = subtractPatterns(pillarPattern, intersectPillars);
                            }
                        }
                        
                        // check if the current layer is to be deposited on
                        const intersect: number[] = intersectPatterns(dynamicPattern, currentPattern);

                        if (intersect.length > 1) {
                            this.drawCommands.splice(i + 1, 0, {
                                type: "Pattern",
                                uniqueKey: `${stackAction.id}-pattern-coated-${i}`,
                                width: width,
                                height: thinDeposit.thicknessTop,
                                z: currentZ,
                                color: thinDeposit.color,
                                pattern: intersect,
                                label: thinDeposit.label,
                                resistType:  thinDeposit.resistType,
                                materialId: thinDeposit.materialId
                            });

                            antiInflatePattern = unionPatterns(antiInflatePattern, intersect);

                            // define new pillars
                            if (thinDeposit.thicknessSide > 0) {
                                inflatedPattern = inflatePattern(intersect, width, thinDeposit.thicknessSide);
                                const newPillarPattern = subtractPatterns(inflatedPattern, antiInflatePattern);
                                pillarPattern = unionPatterns(newPillarPattern, pillarPattern);
                            }
                            
                        }

                        dynamicPattern = subtractPatterns(dynamicPattern, intersect);

                        if (dynamicPattern.length <= 1) {
                            break;
                        }
                    }
                } else {
                    // selective growth
                    const materialTargets = thinDeposit.materialTargets;
    
                    // deposit pattern
                    let dynamicPattern : number[] = [0, 1, 0]; // start with a whole square
                    let inflatedPattern : number[] = [0, 1, 0]; 
                    let antiInflatePattern : number[] = [1]; // this pattern holds the union of all the intersects to make sure we dont overlap pillars
                    let pillarPattern : number[] = [1];
                    let i = this.drawCommands.length - 1;
                    let lastZ = this.drawCommands[i].z - this.drawCommands[i].height;
                    let lastPillarZ = lastZ - thinDeposit.thicknessTop; 
                    for (; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                        const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
                        const currentZ = this.drawCommands[i].z - this.drawCommands[i].height;

                        //first, draw pillar(s) from the previous layer to this one, if exists
                        if (thinDeposit.thicknessSide > 0) {
                            if (pillarPattern.length > 1) {
                                const pillarZ = dc.z - dc.height;
                                const pillarHeight = (pillarZ - lastZ);

                                if (pillarHeight > 0) {
                                    this.drawCommands.splice(i + 1, 0, {
                                        type: "Pattern",
                                        uniqueKey: `${stackAction.id}-selective-subpillar_top_-${i}`,
                                        width: width,
                                        height: thinDeposit.thicknessTop,
                                        z: lastZ,
                                        color: thinDeposit.color,
                                        pattern: pillarPattern,
                                        resistType:  thinDeposit.resistType,
                                        materialId: thinDeposit.materialId
                                    });
                                    lastPillarZ = lastZ;

                                    const subPillarHeight = pillarHeight - thinDeposit.thicknessTop;

                                    if (subPillarHeight > 0) {
                                        this.drawCommands.splice(i + 2, 0, {
                                            type: "Pattern",
                                            uniqueKey: `${stackAction.id}-selective-subpillar_bottom-${i}`,
                                            width: width,
                                            height: subPillarHeight,
                                            z: pillarZ - thinDeposit.thicknessTop,
                                            color: thinDeposit.color,
                                            pattern: pillarPattern,
                                            resistType:  thinDeposit.resistType,
                                            materialId: thinDeposit.materialId
                                        });

                                        lastPillarZ = pillarZ - thinDeposit.thicknessTop;
                                    }
                                }
                            }
                        }

                        
                        // check if the current layer is to be deposited on
                        const intersect: number[] = intersectPatterns(dynamicPattern, currentPattern);

                        if (intersect.length > 1 && dc.materialId && materialTargets.includes(dc.materialId)) {
                            const intersectPillars = intersectPatterns(pillarPattern, currentPattern);
                            if (intersectPillars.length > 1) {
                                pillarPattern = subtractPatterns(pillarPattern, intersectPillars);
                            }

                            this.drawCommands.splice(i + 1, 0, {
                                type: "Pattern",
                                uniqueKey: `${stackAction.id}-selective-coated-${i}`,
                                width: width,
                                height: thinDeposit.thicknessTop,
                                z: currentZ,
                                color: thinDeposit.color,
                                pattern: intersect,
                                label: thinDeposit.label,
                                resistType:  thinDeposit.resistType,
                                materialId: thinDeposit.materialId
                            });
                            lastPillarZ = currentZ + thinDeposit.thicknessTop;

                            if (thinDeposit.thicknessSide > 0) {
                                // define new pillars
                                antiInflatePattern = unionPatterns(antiInflatePattern, intersect);

                                lastZ = dc.z - dc.height;

                                inflatedPattern = inflatePattern(intersect, width, thinDeposit.thicknessSide);
                                const newPillarPattern = subtractPatterns(inflatedPattern, antiInflatePattern);
                                pillarPattern = unionPatterns(newPillarPattern, pillarPattern); 
                            }
                        } else if (intersect.length > 1 && thinDeposit.thicknessSide > 0) {
                            // if we are now examining a layer which isnt our material target, we might still want to deposit
                            // to fill up the bottom of the pillar previously
                            const intersectPillars = intersectPatterns(pillarPattern, currentPattern);

                            if (intersectPillars.length > 1) {
                                let bottomZ = lastPillarZ + thinDeposit.thicknessTop;
                                const topZ = bottomZ - thinDeposit.thicknessTop;
                                if (bottomZ > dc.z - dc.height) {
                                    bottomZ = dc.z - dc.height;
                                }
                                this.drawCommands.splice(i + 1, 0, {
                                    type: "Pattern",
                                    uniqueKey: `${stackAction.id}-selective-subpillar_bottom_fill-${i}`,
                                    width: width,
                                    height: bottomZ - topZ,
                                    z: bottomZ,
                                    color: thinDeposit.color,
                                    pattern: pillarPattern,
                                    resistType: thinDeposit.resistType,
                                    materialId: thinDeposit.materialId
                                });

                                pillarPattern = subtractPatterns(pillarPattern, intersectPillars);
                            }

                            antiInflatePattern = unionPatterns(antiInflatePattern, intersect);
                            inflatedPattern = inflatePattern(intersect, width, thinDeposit.thicknessSide);
                            const newPillarPattern = subtractPatterns(inflatedPattern, antiInflatePattern);
                            pillarPattern = unionPatterns(newPillarPattern, pillarPattern); 
                        } 

                        dynamicPattern = subtractPatterns(dynamicPattern, intersect);
                        if (dynamicPattern.length <= 1) {
                            break;
                        }

                        lastZ = dc.z - dc.height;
                    }

                    // find all layers we just deposited and all the target layers
                    const depositedLayers: number[] = [];
                    const targetLayers: number[] = [];
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;

                        if (dc.materialId && materialTargets.includes(dc.materialId)) {
                            targetLayers.push(i);
                        } else if (dc.materialId === thinDeposit.materialId) {
                            depositedLayers.push(i);
                        }
                    }

                    // iterate through all the deposited layers and check for either vertical or horizontal intersections
                    for (let k = 0; k < depositedLayers.length; k++) {
                        const depositedPattern : number[] = this.drawCommands[depositedLayers[k]].pattern ?? [1];
                        const inflatedPattern = inflatePattern(depositedPattern, 2*width, 1);

                        let touchPattern: number[] = [1];
                        for (let j = 0; j < targetLayers.length; j++) { 
                            const targetPattern : number[] = this.drawCommands[targetLayers[j]].pattern ?? [1]; 
                            const inflatedTargetPattern = inflatePattern(targetPattern, width, thinDeposit.thicknessSide);

                            if (this.drawCommands[depositedLayers[k]].z < this.drawCommands[targetLayers[j]].z - this.drawCommands[targetLayers[j]].height) {
                                // no intersection
                                break;
                            }

                            if (this.drawCommands[depositedLayers[k]].z - this.drawCommands[depositedLayers[k]].height >= this.drawCommands[targetLayers[j]].z) {
                                // no intersection
                                continue;
                            }
                            const sidePattern = intersectPatterns(inflatedPattern, targetPattern);

                            if (sidePattern.length > 1) {
                                // the deposited layer is just beside a target layer - keep it and keep track of new top/bottom
                                const intersect = intersectPatterns(inflatedTargetPattern, depositedPattern);
                                touchPattern = unionPatterns(touchPattern, intersect);
                            }
                        }

                        // if it didn't touch anything - mark it for removal
                        this.drawCommands[depositedLayers[k]].pattern = touchPattern;
                    }

                    // splice out all layers that didn't touch anything
                    for (let k = 0; k < depositedLayers.length; k++) {
                        const depositedPattern : number[] = this.drawCommands[depositedLayers[k]].pattern ?? [1];
                        if (depositedPattern.length <= 1) {
                            this.drawCommands.splice(depositedLayers[k], 1);
                        }
                    }
                }

                this.mergeHorizontal(thinDeposit.materialId);

                this.debugLog("----- END THIN DEPOSIT ------");

            } else if (stackAction.type === StackChange.ThermalOxidation) {
                const thermalOxidation = stackAction as ThermalOxidation;

                // first, find the material and whats the max height
                let materialTargets = thermalOxidation.materialTargets;

                this.drawCommands.sort(compareByZandHeight);

                let depositPattern : number[] = [0, 1, 0]; // start with a whole square to etch

                // first, find material if it's empty
                if (materialTargets.length === 0) {
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                        const currentPattern : number[] = dc.pattern ?? [0]; 
    
                        if (dc.resistType === ResistType.NONE) {
                            const intersect = intersectPatterns(currentPattern, depositPattern);

                            if (intersect.length > 1 && dc.materialId) {
                                materialTargets = [dc.materialId];
                                break;
                            }
                        } 

                        depositPattern = subtractPatterns(depositPattern, currentPattern);
                    }
                }

                this.debugLog("materialTargets: ", materialTargets);

                // second, merge layers if they share vertical
                this.drawCommands.sort(compareByZ);
                for (const materialTarget of materialTargets) {
                    this.mergeVertical(stackAction, materialTarget);
                }
                this.drawCommands.sort(compareByZandHeight);

                const oxidationDepth = thermalOxidation.oxidationDepth;

                const previouslyOxidizedLayers: string[] = [];
                for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                    const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;

                    if (dc.materialId === thermalOxidation.materialId) {
                        previouslyOxidizedLayers.push(dc.uniqueKey);
                    }
                }

                // third, deposit pattern
                if (oxidationDepth > 0) {
                    let dynamicPattern : number[] = [0, 1, 0]; // start with a whole square
                    let inflatedPattern : number[] = [0, 1, 0]; 
                    let antiInflatePattern : number[] = [1]; // this pattern holds the union of all the intersects to make sure we dont overlap pillars
                    let pillarPattern : number[] = [1];
                    let i = this.drawCommands.length - 1;
                    let lastZ = this.drawCommands[i].z - this.drawCommands[i].height;
                    let lastPillarZ = lastZ - 10;
                    for (; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                        const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
                        const currentZ = this.drawCommands[i].z - this.drawCommands[i].height;

                        //first, draw pillar(s) from the previous layer to this one, if exists
                        if (pillarPattern.length > 1) {
                            const pillarZ = this.drawCommands[i].z - this.drawCommands[i].height;
                            const pillarHeight = (pillarZ - lastZ);

                            if (pillarHeight > 0) {
                                let maxHeight = oxidationDepth * 2;
                                let maxZ = lastZ + oxidationDepth;
                                if (maxZ > 100) {
                                    maxHeight -= maxZ - 100;
                                    maxZ = 100;
                                }
                                this.drawCommands.splice(i + 1, 0, {
                                    type: "Pattern",
                                    uniqueKey: `${stackAction.id}-oxidized-subpillar_top_-${i}`,
                                    width: width,
                                    height: maxHeight,
                                    z: maxZ,
                                    color: thermalOxidation.color,
                                    pattern: pillarPattern,
                                    resistType:  ResistType.NONE,
                                    materialId: thermalOxidation.materialId
                                });
                                lastPillarZ = maxZ;

                                const subPillarHeight = pillarHeight - 2 * oxidationDepth;

                                if (subPillarHeight > 0) {
                                    this.drawCommands.splice(i + 2, 0, {
                                        type: "Pattern",
                                        uniqueKey: `${stackAction.id}-oxidized-subpillar_bottom-${i}`,
                                        width: width,
                                        height: subPillarHeight,
                                        z: pillarZ - oxidationDepth,
                                        color: thermalOxidation.color,
                                        pattern: pillarPattern,
                                        resistType:  ResistType.NONE,
                                        materialId: thermalOxidation.materialId
                                    });
                                    lastPillarZ = pillarZ - oxidationDepth;
                                }

                                lastZ = pillarZ;
                            }
                        }

                        
                        // check if the current layer is to be deposited on
                        const intersect: number[] = intersectPatterns(dynamicPattern, currentPattern);

                        if (intersect.length > 1 && dc.materialId && materialTargets.includes(dc.materialId)) {
                            const intersectPillars = intersectPatterns(pillarPattern, currentPattern);
                            if (intersectPillars.length > 1) {
                                pillarPattern = subtractPatterns(pillarPattern, intersectPillars);
                            }


                            const maxOxidation = Math.min(oxidationDepth, dc.height);

                            this.drawCommands.splice(i + 1, 0, {
                                type: "Pattern",
                                uniqueKey: `${stackAction.id}-oxidized-coated-${i}`,
                                width: width,
                                height: maxOxidation*2,
                                z: currentZ + maxOxidation,
                                color: thermalOxidation.color,
                                pattern: intersect,
                                label: thermalOxidation.label,
                                resistType:  ResistType.NONE,
                                materialId: thermalOxidation.materialId
                            });
                            lastPillarZ = currentZ + maxOxidation;

                            antiInflatePattern = unionPatterns(antiInflatePattern, intersect);

                            // define new pillars
                            lastZ = dc.z - dc.height;
                            inflatedPattern = inflatePattern(intersect, width, oxidationDepth);
                            const newPillarPattern = subtractPatterns(inflatedPattern, antiInflatePattern);
                            pillarPattern = unionPatterns(newPillarPattern, pillarPattern); 
                        } else if (intersect.length > 1) {
                            // if we are now examining a layer which isnt our material target, we might still want to deposit
                            // some thermal oxide to fill up the bottom of the pillar previously
                            const intersectPillars = intersectPatterns(pillarPattern, currentPattern);

                            if (intersectPillars.length > 1) {
                                let maxHeight = oxidationDepth * 2;
                                let maxZ = lastPillarZ + maxHeight;
                                if (maxZ > dc.z) {
                                    maxHeight -= maxZ - dc.z;
                                    maxZ = dc.z;
                                }

                                if (maxHeight > 0) {
                                    this.drawCommands.splice(i + 1, 0, {
                                        type: "Pattern",
                                        uniqueKey: `${stackAction.id}-oxidized-subpillar_bottom_fill-${i}`,
                                        width: width,
                                        height: maxHeight,
                                        z: maxZ,
                                        color: thermalOxidation.color,
                                        pattern: pillarPattern,
                                        resistType:  ResistType.NONE,
                                        materialId: thermalOxidation.materialId
                                    });
    
                                    pillarPattern = subtractPatterns(pillarPattern, intersectPillars);
                                }
                            }

                            antiInflatePattern = unionPatterns(antiInflatePattern, intersect);

                            // define new pillars
                            lastZ = dc.z;
                            inflatedPattern = inflatePattern(intersect, width, oxidationDepth);
                            const newPillarPattern = subtractPatterns(inflatedPattern, antiInflatePattern);
                            pillarPattern = unionPatterns(newPillarPattern, pillarPattern); 
                        } else if (pillarPattern.length > 1 && !(dc.materialId && materialTargets.includes(dc.materialId))) {
                            // modify the Z for materials that are not the target material and are touching our side pillar
                            inflatedPattern = inflatePattern(pillarPattern, width, 1);
                            const intersectPillars = intersectPatterns(inflatedPattern, currentPattern);
                            if (intersectPillars.length > 1) {
                                lastZ = dc.z;
                            }
                        }

                        dynamicPattern = subtractPatterns(dynamicPattern, intersect);
                        if (dynamicPattern.length <= 1) {
                            break;
                        }
                    }

                    const oxidizedLayers: number[] = [];
                    const targetLayers: number[] = [];
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;

                        if (dc.materialId && materialTargets.includes(dc.materialId)) {
                            targetLayers.push(i);
                        } else if (dc.materialId === thermalOxidation.materialId) {
                            if (!previouslyOxidizedLayers.includes(dc.uniqueKey)) {
                                oxidizedLayers.push(i);
                            }
                        }
                    }

                     // sideways oxidizing - inflate all layers that touch on one of its sides the material target
                    for (let k = 0; k < oxidizedLayers.length; k++) {
                        const newPattern : number[] = this.drawCommands[oxidizedLayers[k]].pattern ?? [1];
                        const inflatedPattern = inflatePattern(newPattern, width*2, oxidationDepth*2);

                        for (let j = 0; j < targetLayers.length; j++) {                             
                            if (this.drawCommands[oxidizedLayers[k]].z <= this.drawCommands[targetLayers[j]].z - this.drawCommands[targetLayers[j]].height) {
                                // no intersection
                                break;
                            }
                            if (this.drawCommands[oxidizedLayers[k]].z - this.drawCommands[oxidizedLayers[k]].height >= this.drawCommands[targetLayers[j]].z) {
                                // no intersection
                                continue;
                            }
                            if (this.drawCommands[oxidizedLayers[k]].z - this.drawCommands[oxidizedLayers[k]].height < this.drawCommands[targetLayers[j]].z - this.drawCommands[targetLayers[j]].height) {
                                //continue;
                            }
                            
                            const targetPattern : number[] = this.drawCommands[targetLayers[j]].pattern ?? [0];
                            const sidePattern = intersectPatterns(inflatedPattern, targetPattern);

                            if (sidePattern.length > 1) {
                                const currentDepositPattern : number[] = this.drawCommands[oxidizedLayers[k]].pattern ?? [0]; 
                                this.drawCommands[oxidizedLayers[k]].pattern = unionPatterns(currentDepositPattern, sidePattern);
                            }
                        }
                    }

                    // now we added all of the oxidized layer correctly, but we didn't "etch"/"chew" through the target material layer
                    // in these steps, we chew back

                    // first, merge all the oxidized layers by vertical
                    this.mergeVertical(stackAction, thermalOxidation.materialId);

                    // check all oxidized layers vs. all material target layers and etch the material layers upon intersection
                    this.checkAndEtchIntersections(stackAction, materialTargets);
                    // the etching might have split/created new material layers. so we do another pass to verify
                    this.checkAndEtchIntersections(stackAction, materialTargets);
                    

                    // now, we might have overgrown our oxidized layer to overlap existing layers that are non target material. check throught that
                    this.checkAndRemoveIntersections(stackAction, materialTargets, width);
                    // the function might add new layers, so we do another pass
                    this.checkAndRemoveIntersections(stackAction, materialTargets, width);

                    for (const materialTarget of materialTargets) {
                        this.mergeHorizontal(materialTarget);
                    }
                    this.mergeHorizontal(thermalOxidation.materialId);
                }
            } else if (stackAction.type === StackChange.Pattern) {
                const patternStack = stackAction as Pattern;

                this.drawCommands.sort(compareByZandHeight);

                let patternPattern: number[] = patternStack.pattern;
                // we must invert the exposure pattern, unless the user marked to invert it
                if (!patternStack.invertPattern) {
                    patternPattern = reversePattern(patternPattern); // inserts a 0 at the first location, is equal to inverting it
                }

                for (let i = this.drawCommands.length - 1; i >= 1; i--) {
                    const dc = this.drawCommands[i] ?? EMPTY_DC;
                    const currentPattern : number[] = dc.pattern ?? [0]; 
                    const resistType = dc.resistType;

                    const intersect: number[] = intersectPatterns(patternPattern, currentPattern);

                    if (resistType === ResistType.POSITIVE || resistType === ResistType.NEGATIVE) {
                        this.drawCommands[i] = {
                            type: "PatternPreDevelop",
                            uniqueKey: `${stackAction.id}-pattern-${resistType}-${i}`,
                            width: width,
                            height: dc.height,
                            z: dc.z,
                            color: dc.color,
                            pattern: currentPattern,
                            exposedPattern: intersect,
                            developable: resistType === ResistType.POSITIVE ? "EXPOSED" : "NON_EXPOSED",
                            resistType: ResistType.POSITIVE,
                            materialId: dc.materialId
                        };
                    } else {
                        patternPattern = subtractPatterns(patternPattern, currentPattern);
                    }
                }

                this.drawCommands.sort(compareByZandHeight);
            } else if (stackAction.type === StackChange.Develop) {
                const developStack = stackAction as Develop;
                const materialTargets = developStack.materialTargets;

                // this pattern is used just for liftoff resists
                let etchPattern : number[] = [0, 1, 0]; // start with a whole square to etch

                this.drawCommands.sort(compareByZandHeight);

                for (let i = this.drawCommands.length - 1; i >= 1; i--) {
                    const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                    const originalPattern : number[] = dc.pattern ?? [0]; 
                    const exposedPattern : number[] = dc.exposedPattern ?? [0]; 
                    const nonExposedPattern = subtractPatterns(originalPattern, exposedPattern);

                    if (dc.resistType === ResistType.LOR) {
                        const inflatedPattern = inflatePattern(etchPattern, width, 3);
                        this.etchOneLayerVertically(dc, i, originalPattern, inflatedPattern, dc.z, stackAction.id);
                    } else if (dc.resistType === ResistType.ADHESION_PROMOTER) {
                        this.etchOneLayerVertically(dc, i, originalPattern, etchPattern, dc.z, stackAction.id);
                    } else if (dc.resistType !== ResistType.NONE && (dc.developable === "EXPOSED" || dc.developable === "NON_EXPOSED")) {
                        if (materialTargets.length === 0 || (dc.materialId && materialTargets.includes(dc.materialId))) {
                            this.drawCommands[i] = {
                                type: "Pattern",
                                uniqueKey: `${stackAction.id}-pattern-developed-${dc.developable}-${i}`,
                                width: width,
                                height: dc.height,
                                z: dc.z,
                                color: dc.developable === "EXPOSED" ? dc.color : dc.color.getDarker(),
                                pattern: dc.developable === "EXPOSED" ? nonExposedPattern : exposedPattern,
                                resistType: dc.resistType,
                                materialId: dc.materialId
                            };
                        }
                    } 

                    const currentPattern : number[] = this.drawCommands[i].pattern ?? [0]; 
                    etchPattern = subtractPatterns(etchPattern, currentPattern);
                }
            } else if (stackAction.type === StackChange.WetEtch) {
                if (this.drawCommands.length > 1) {
                    this.drawCommands.pop();
                }
            } else if (stackAction.type === StackChange.Clean) {
                const cleanStack = stackAction as Clean;
                if (this.drawCommands.length > 1) {
                    // remove all targeted layers
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc = this.drawCommands[i];
                        if (dc.materialId && cleanStack.materialTargets.includes(dc.materialId)) {
                            this.drawCommands.splice(i, 1);
                        }
                    }

                    // remove unsuspported layers
                    this.drawCommands.sort(compareByZ);

                    if (cleanStack.keepFloatingStructures !== true) {
                        this.removeUnsupportedLayers();
                    }

                    // check minimum Z
                    let minZ = 0;
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        if (this.drawCommands[i].z > minZ) {
                            minZ = this.drawCommands[i].z;
                        }
                    }
                    // add the difference
                    const diff = this.maxHeight - minZ;
                    if (diff > 0) {
                        for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                            this.drawCommands[i].z += diff;
                        }
                    }

                    this.drawCommands.sort(compareByZandHeight);
                }
            } else if (stackAction.type === StackChange.StripResist) {
                if (this.drawCommands.length > 1) {
                    // remove all resist layers
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        if (this.drawCommands[i].resistType !== ResistType.NONE) {
                            this.drawCommands.splice(i, 1);
                        }
                    }

                    // remove unsuspported layers
                    this.drawCommands.sort(compareByZ);
                    this.removeUnsupportedLayers();

                    // check minimum Z
                    let minZ = 0;
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        if (this.drawCommands[i].z > minZ) {
                            minZ = this.drawCommands[i].z;
                        }
                    }
                    // add the difference
                    const diff = this.maxHeight - minZ;
                    if (diff > 0) {
                        for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                            this.drawCommands[i].z += diff;
                        }
                    }

                    this.drawCommands.sort(compareByZandHeight);
                }
            } else if (stackAction.type === StackChange.LiftOff) {
                if (this.drawCommands.length > 0) {
                    // sort by z
                    // TODO: do we always want to do this?
                    this.drawCommands.sort(compareByZ);

                    // first, remove all resist layers
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        if (this.drawCommands[i].resistType !== ResistType.NONE) {
                            this.drawCommands.splice(i, 1);
                        }
                    }

                    this.removeUnsupportedLayers();

                    this.drawCommands.sort(compareByZandHeight);
                }
            } else if (stackAction.type === StackChange.Polish) {
                const polishStack = stackAction as Polish;

                this.drawCommands.sort(compareByZandHeight);

                if (this.drawCommands.length > 0) {
                    const maxHeight = this.drawCommands[0].z - (this.drawCommands[this.drawCommands.length - 1].z - this.drawCommands[this.drawCommands.length - 1].height);
                    const polishDepth = Math.round(maxHeight * polishStack.polishPercentage / 100);
                    const polishZ = (this.drawCommands[this.drawCommands.length - 1].z - this.drawCommands[this.drawCommands.length - 1].height) + polishDepth;

                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                        const currentPattern : number[] = dc.pattern ?? [0]; 

                        if (polishZ < dc.z - dc.height) {
                            break;
                        }

                        this.etchOneLayerVertically(dc, i, currentPattern, currentPattern, polishZ, stackAction.id);
                    }
                }
            } else if (stackAction.type === StackChange.Etch) {
                const etchStack = stackAction as Etch;

                this.debugLog("> ETCH START")
                
                let materialTargets = etchStack.materialTargets;

                this.drawCommands.sort(compareByZandHeight);

                const originalEtchPattern = etchStack.invertPattern ? reversePattern(etchStack.etchPattern) : etchStack.etchPattern;
                let etchPattern = originalEtchPattern;
                let maxEtchDepth = 0;

                // first, find material if it's empty
                if (materialTargets.length === 0) {
                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                        const currentPattern : number[] = dc.pattern ?? [0]; 
    
                        if (dc.resistType === ResistType.NONE || etchStack.bloxID === BloxTypes.ImprintLitho)  {
                            const intersect = intersectPatterns(currentPattern, etchPattern);

                            if (intersect.length > 1 && dc.materialId) {
                                materialTargets = [dc.materialId];
                                break;
                            }
                        } 

                        if (etchStack.etchBuriedLayers !== true) {
                            etchPattern = subtractPatterns(etchPattern, currentPattern);
                        }
                    }
                }

                this.debugLog("materialTargets: ", materialTargets);

                // second, merge layers if they share vertical
                this.drawCommands.sort(compareByZ);
                for (const materialTarget of materialTargets) {
                    this.mergeVertical(stackAction, materialTarget);
                }
                maxEtchDepth = this.calculateMaxHeight(materialTargets);

                this.drawCommands.sort(compareByZandHeight);

                // fourth, etch it vertically
                const etchDepth = Math.round(maxEtchDepth * etchStack.etchPercentage / 100);
                // this dictionary holds patterns for which we started etching
                const etchTops: {[topZ: number] : number[]} = {}
                this.debugLog("maxEtchDepth: ", maxEtchDepth, " etchDepth: ", etchDepth)
                if (maxEtchDepth > 0) {
                    if (etchDepth > 0) {
                        etchPattern = originalEtchPattern;
                        
                        for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                            const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                            let etched = false;

                            if (dc.materialId && materialTargets.includes(dc.materialId)) {
                                let intersect = intersectPatterns(dc.pattern, etchPattern);
                                this.debugLog("Going to etch layer ", dc.z, dc.height, dc.color, dc.pattern);
                                this.debugLog("With etchPattern, ", etchPattern);
                                this.debugLog("intersect, ", intersect);
                                
                                if (intersect.length > 1) {
                                    etched = true;
                                    this.debugLog("ETCHING");
                                    
                                    let etchZ = 0;
                                    const etchTopZs = Object.keys(etchTops);
                                    etchTopZs.sort();
                                    for (let j = etchTopZs.length - 1; j >= 0; j--) {
                                        const topZ = parseFloat(etchTopZs[j]);
                                        const pattern = etchTops[topZ];
                                        const tmp = intersectPatterns(intersect, pattern);
                                        if (tmp.length > 1) {
                                            etchZ = etchDepth + topZ;
                                            let etchingBeyondLayer = false;

                                            if (etchZ >= dc.z) {
                                                etchingBeyondLayer = true;
                                                etchZ = dc.z;
                                            }

                                            this.debugLog("Etching pattern ", tmp, " until etchZ ", etchZ);

                                            const ret = this.etchOneLayerVertically(this.drawCommands[i], i, this.drawCommands[i].pattern, tmp, etchZ, stackAction.id);
                                            if (ret === -1) {
                                                // layer was removed
                                                this.debugLog("etchOneLayerVertically removed layer totally");
                                                
                                                intersect = [1];
                                                break;
                                            } else if (ret === 1) {
                                                // new layer was added
                                                this.debugLog("etchOneLayerVertically added a new layer");
                                                i++;
                                            }
                                            

                                            if (!etchingBeyondLayer && etchStack.etchBuriedLayers !== true) {
                                                // if this is a partial etch through this later, subtract the etch pattern from it
                                                intersect = subtractPatterns(intersect, tmp);
                                                etchPattern = subtractPatterns(etchPattern, tmp);

                                                if (intersect.length <= 1) {
                                                    break
                                                }
                                            }

                                            
                                        }
                                    }

                                    if (intersect.length > 1) {
                                        const topZ = dc.z - dc.height;
                                        if (topZ in etchTops) {
                                            etchTops[topZ] = unionPatterns(etchTops[topZ], intersect);
                                        } else {
                                            etchTops[topZ] = intersect;
                                        }
                                        etchZ = etchDepth + topZ;

                                        let etchingBeyondLayer = false;
                                        if (etchZ >= dc.z) {
                                            etchingBeyondLayer = true;
                                            etchZ = dc.z;
                                        }

                                        this.debugLog("Etching pattern ", etchPattern, " until etchZ ", etchZ);
                                        const ret = this.etchOneLayerVertically(this.drawCommands[i], i, this.drawCommands[i].pattern, etchPattern, etchZ, stackAction.id);
                                        if (ret === -1) {
                                            // layer was removed
                                            this.debugLog("etchOneLayerVertically removed layer totally");
                                            continue;
                                        } else if (ret === 1) {
                                            // new layer was added
                                            this.debugLog("etchOneLayerVertically added a new layer");
                                            i++;
                                        }

                                        if (!etchingBeyondLayer && etchStack.etchBuriedLayers !== true) {
                                            // if this is a partial etch through this later, subtract the etch pattern from it
                                            etchPattern = subtractPatterns(etchPattern, this.drawCommands[i].pattern);
                                        }
                                    }
                                }
                            }

                            if (!etched && etchStack.etchBuriedLayers !== true) {
                                // if this layer wasn't etched because of selectivity, or if it's a sidewalle - continue etching
                                etchPattern = subtractPatterns(etchPattern, this.drawCommands[i].pattern);
                            }
                            
                            if (etchPattern.length <= 1) {
                                break;
                            }
                        }
                    }
                }

                // fifth, re-merge the layers horizontally
                this.drawCommands.sort(compareByZ);
                let maxRelativeWidth = 0;
                for (const materialTarget of materialTargets) {
                    const relativeWidth = this.mergeHorizontal(materialTarget);
                    this.debugLog(materialTarget, "relativeWidth: ", relativeWidth)
                    if (relativeWidth > maxRelativeWidth) {
                        maxRelativeWidth = relativeWidth;
                    }
                }

                // fifth, sideways etch
                let sidewaysEtchDepth = Math.round(width * maxRelativeWidth * etchStack.sidewaysEtchPercentage / 100);
                if (etchStack.isotropicEtch) {
                    sidewaysEtchDepth = etchDepth;
                }
                this.debugLog("sidewaysEtchDepth: ", sidewaysEtchDepth);
                if (sidewaysEtchDepth > 0) {
                    this.drawCommands.sort(compareByZandHeight);

                    let sidewaysEtchPattern: number[] = [1];

                    // etch sideways
                    etchPattern = originalEtchPattern;

                    for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                        const dc: DrawCommand = this.drawCommands[i] ?? EMPTY_DC;
                        const currentPattern : number[] = dc.pattern ?? [0]; 
                        etchPattern = subtractPatterns(etchPattern, currentPattern);

                        if (dc.materialId && materialTargets.includes(dc.materialId)) {
                            // look for all other patterns that might be intersecting
                            // TODO: this can be quicker
                            let limitPattern: number[] = [1];
                            for (let j = this.drawCommands.length-1; j >= 0; j--) {
                                const p : number[] = this.drawCommands[j].pattern ?? [0]; 
                                const otherMaterialId = this.drawCommands[j].materialId;
                                if (!(otherMaterialId && materialTargets.includes(otherMaterialId))) {
                                    if (this.drawCommands[j].z - this.drawCommands[j].height >= this.drawCommands[i].z) {
                                        // no intersection
                                        break;
                                    }
                                    if (this.drawCommands[j].z <= this.drawCommands[i].z - this.drawCommands[i].height) {
                                        // no intersection
                                        continue;
                                    }
                                    limitPattern = unionPatterns(limitPattern, p);
                                }
                            }

                            const inflatedPattern = inflatePatternWithLimits(etchPattern, width, sidewaysEtchDepth, limitPattern);
                            //const inflatedPattern = inflatePattern(etchPattern, width, sidewaysEtchDepth);
                            const intersect = intersectPatterns(currentPattern, inflatedPattern);

                            if (intersect.length > 1) {
                                const invertedPattern = subtractPatterns(currentPattern, inflatedPattern);

                                sidewaysEtchPattern = unionPatterns(intersect, sidewaysEtchPattern);

                                if (invertedPattern.length <= 1) {
                                    // nothing is protected, no need for sidewall etching
                                    this.debugLog("Spliced out layer ", dc.z, dc.height, dc.color, dc.pattern);
                                    this.drawCommands.splice(i, 1);
                                } else {
                                    const sidewaysEtchPattern = subtractPatterns(inflatedPattern, etchPattern);

                                    const newDrawCommand = Object.assign({}, dc);

                                    // replace the entire layer with the protected pattern
                                    const newDopeCommands = dc.dopeCommands?.map((dopeCommand, index) => {
                                        dopeCommand.pattern = intersectPatterns(dopeCommand.pattern, invertedPattern);
                                        return dopeCommand;
                                    });

                                    newDrawCommand.uniqueKey = `${stackAction.id}-pattern-sideetched-${i}`;
                                    newDrawCommand.pattern = invertedPattern;
                                    newDrawCommand.dopeCommands = newDopeCommands;
                                    newDrawCommand.sidewaysPattern = sidewaysEtchPattern;

                                    this.drawCommands[i] = newDrawCommand;
                                }
                            }
                        }

                        if (etchPattern.length <= 1) {
                            break;
                        }
                    }
                }

                

                // check and remove unsupproted layers
                if (sidewaysEtchDepth > 0 || etchStack.etchBuriedLayers) {
                    if (etchStack.keepFloatingStructures !== true) {
                        this.drawCommands.sort(compareByZ);
                        this.removeUnsupportedLayers();
                    } 
                }

                this.debugLog("> ETCH END");
            } else if (stackAction.type === StackChange.IonImplantation) {
                const implantStack = stackAction as IonImplantation;

                this.debugLog("> IMPLANT START");

                // TODO: Bug with implanting layers of multiple heights:
                // http://localhost:3000/process-editor/4dd6fb7f-d360-43a0-8051-7b4e2f784643

                let implantPattern : number[] = [0, 1, 0]; // start with a whole square to etch

                // this dictionary holds patterns for which we started implants
                const implantTops: {[topZ: number] : number[]} = {}

                for (let i = this.drawCommands.length - 1; i >= 0; i--) {
                    this.debugLog("Layer ", i, " z:", this.drawCommands[i].z, " h: ", this.drawCommands[i].height, " pattern: ", this.drawCommands[i].pattern);
                    const currentPattern : number[] = this.drawCommands[i].pattern ?? [0];

                    const materialTargets = implantStack.materialTargets;
                    
                    let intersect: number[] = intersectPatterns(implantPattern, currentPattern);

                    const resistType = this.drawCommands[i].resistType;
                    const materialId = this.drawCommands[i].materialId;
                    let isLayerMask = true;
                    if (materialTargets.length === 0 || (materialId && materialTargets.includes(materialId))) {
                        // if there is a material target and this is the right layer
                        if ( resistType === ResistType.NONE) { 
                            // if it's a non resist
                            if (intersect.length > 1) {
                                // this means it's a flat doping - dope the whole layer

                                let implantZ = 0;
                                const implantTopZs = Object.keys(implantTops);
                                implantTopZs.sort();
                                for (let j = implantTopZs.length - 1; j >= 0; j--) {
                                    const topZ = parseFloat(implantTopZs[j]);
                                    const pattern = implantTops[topZ];
                                    const tmp = intersectPatterns(intersect, pattern);
                                    if (tmp.length > 1) {
                                        implantZ = implantStack.thickness + topZ;

                                        const newDrawCommand = this.drawCommands[i];

                                        if (implantZ >= newDrawCommand.z) {
                                            isLayerMask = false;
                                            implantZ = newDrawCommand.z;
                                        }

                                        

                                        const depth = newDrawCommand.height - (newDrawCommand.z - implantZ);
                                        this.debugLog("Implanting pattern ", tmp, " until implantZ ", implantZ, " from topZ", topZ, " depth: ", depth);
                                        const newDopeCommand: DopeCommand = {
                                            id: implantStack.id,
                                            pattern: intersect,
                                            depth: depth,
                                            label: implantStack.label,
                                            color: implantStack.color,
                                            cornerRadius: 0,
                                            flipped: false,
                                            sideDope: false,
                                            thickness: depth
                                        };
                                        if (newDrawCommand.dopeCommands) {
                                            newDrawCommand.dopeCommands.push(newDopeCommand);
                                        } else {
                                            newDrawCommand.dopeCommands = [newDopeCommand];
                                        }
                                        this.drawCommands[i] = newDrawCommand;
                                        

                                        // if this is a partial etch through this later, subtract the etch pattern from it
                                        this.debugLog("Subtracting patten ", tmp, " from ", intersect);
                                        intersect = subtractPatterns(intersect, tmp);
                                        if (intersect.length <= 1) {
                                            break;
                                        }
                                    }
                                }

                                if (intersect.length > 1) {
                                    this.debugLog("No topZ, implanting")
                                    const newDrawCommand = this.drawCommands[i];

                                    const topZ = newDrawCommand.z - newDrawCommand.height;
                                    if (topZ in implantTops) {
                                        implantTops[topZ] = unionPatterns(implantTops[topZ], intersect);
                                    } else {
                                        implantTops[topZ] = intersect;
                                    }

                                    let depth = implantStack.thickness;
                                    if (depth > newDrawCommand.height) {
                                        depth = newDrawCommand.height;
                                        isLayerMask = false;
                                    }

                                    this.debugLog("Implanting pattern ", intersect, " with depth ", depth);

                                    const newDopeCommand: DopeCommand = {
                                        id: implantStack.id,
                                        pattern: intersect,
                                        depth: depth,
                                        label: implantStack.label,
                                        color: implantStack.color,
                                        cornerRadius: 0,
                                        flipped: false,
                                        sideDope: false,
                                        thickness: depth
                                    };
                                    if (newDrawCommand.dopeCommands) {
                                        newDrawCommand.dopeCommands.push(newDopeCommand);
                                    } else {
                                        newDrawCommand.dopeCommands = [newDopeCommand];
                                    }
                                    this.drawCommands[i] = newDrawCommand;
                                }
                            } 
                        }
                    }

                    if (isLayerMask) {
                        implantPattern = subtractPatterns(implantPattern, currentPattern);
                        if (implantPattern.length <= 1) {
                            break;
                        }
                    }
                }

                this.debugLog("> IMPLANT END");
            } else if (stackAction.type === StackChange.DopingDiffusion) {
                const diffusionStack = stackAction as DopingDiffusion;

                this.drawCommands.sort(compareByZ);
                // check for spin coated dopants
                const dopedPatterns: { [key: number]: number[] } = {}
                const dopantLayers: number[] = [];
                const nonDopantLayers: number[] = [];
                const dopeMaterials: Set<string> = new Set();
                for (let i = 0; i < this.drawCommands.length; i++) {
                    if (this.drawCommands[i].isDopant === true) {
                        dopeMaterials.add(this.drawCommands[i].materialId ?? this.drawCommands[i].uniqueKey);
                    } 
                }
                // if exists, merge them vertically
                for (const materialId of dopeMaterials) {
                    this.mergeVertical(stackAction, materialId);
                }
                // collect layer indexes
                for (let i = 0; i < this.drawCommands.length; i++) {
                    if (this.drawCommands[i].isDopant === true) {
                        dopantLayers.push(i);

                        const currentPattern = this.drawCommands[i].pattern ?? [0];
                        const dopedPattern : number[] = dopedPatterns[this.drawCommands[i].z] ?? [1];
                        dopedPatterns[this.drawCommands[i].z] = unionPatterns(dopedPattern, currentPattern);
                    } else {
                        if (this.drawCommands[i].resistType === ResistType.NONE) { 
                            // add to layers that might be doped
                            nonDopantLayers.push(i);
                        }
                    }
                }
                // vertical doping
                for (let j = 0; j < nonDopantLayers.length; j++) {
                    const i = nonDopantLayers[j];
                    const topZ = this.drawCommands[i].z - this.drawCommands[i].height;
                    const currentPattern : number[] = this.drawCommands[i].pattern ?? [0];
                    const dopePattern : number[] = dopedPatterns[topZ] ?? [1];

                    const intersect: number[] = intersectPatterns(dopePattern, currentPattern);
                    if (intersect.length > 1) {
                        const newDrawCommand = this.drawCommands[i];

                        const newDopeCommand: DopeCommand = {
                            id: diffusionStack.id,
                            pattern: intersect,
                            depth: 0,
                            label: "",
                            color: new Color(0, 0, 0, 0.25),
                            cornerRadius: 0,
                            flipped: false,
                            sideDope: false,
                            thickness: 0
                        };
                        if (newDrawCommand.dopeCommands) {
                            newDrawCommand.dopeCommands.push(newDopeCommand);
                        } else {
                            newDrawCommand.dopeCommands = [newDopeCommand];
                        }
                        this.drawCommands[i] = newDrawCommand;
                    }
                }
                // sideways doping
                for (let k = 0; k < dopantLayers.length; k++) {
                    for (let j = 0; j < nonDopantLayers.length; j++) { 
                        if (this.drawCommands[dopantLayers[k]].z - this.drawCommands[dopantLayers[k]].height >= this.drawCommands[nonDopantLayers[j]].z) {
                            // no intersection
                            break;
                        }
                        if (this.drawCommands[dopantLayers[k]].z <= this.drawCommands[nonDopantLayers[j]].z - this.drawCommands[nonDopantLayers[j]].height) {
                            // no intersection
                            continue;
                        }

                        const dopePattern : number[] = this.drawCommands[dopantLayers[k]].pattern ?? [1];
                        const inflatedPattern = inflatePattern(dopePattern, width, 1);
                        //const subtractedPattern = subtractPatterns(inflatedPattern, dopePattern);
                        const currentPattern : number[] = this.drawCommands[nonDopantLayers[j]].pattern ?? [0];
                        const sideImplantPattern = intersectPatterns(inflatedPattern, currentPattern);

                        if (sideImplantPattern.length > 1) {
                            const newDrawCommand = this.drawCommands[nonDopantLayers[j]];

                            const newDopeCommand: DopeCommand = {
                                id: diffusionStack.id,
                                pattern: sideImplantPattern,
                                depth: newDrawCommand.height,
                                label: "",
                                color: new Color(0, 0, 0, 0.25),
                                cornerRadius: 0,
                                flipped: false,
                                sideDope: true,
                                thickness: 0
                            };
                            if (newDrawCommand.dopeCommands) {
                                newDrawCommand.dopeCommands.push(newDopeCommand);
                            } else {
                                newDrawCommand.dopeCommands = [newDopeCommand];
                            }
                            this.drawCommands[nonDopantLayers[j]] = newDrawCommand;
                        }
                    }
                }

                // dopant diffusion
                for (let i = 0; i < this.drawCommands.length; i++) {
                    const dc = this.drawCommands[i] ?? EMPTY_DC;
                    const parentPattern : number[] = this.drawCommands[i].pattern ?? [0];

                    
                    if (dc.dopeCommands) {
                        for (let i = 0; i < dc.dopeCommands.length; i++) {
                            const pattern = dc.dopeCommands[i].pattern;

                            if (!dc.dopeCommands[i].sideDope) {
                                let inflatedPattern = inflatePattern(pattern, width, diffusionStack.inflate);
                                inflatedPattern = intersectPatterns(inflatedPattern, parentPattern);
    
                                let newDepth = dc.dopeCommands[i].depth + diffusionStack.inflate;
                                let radius = diffusionStack.inflate/2;

                                if (radius > newDepth) {
                                    radius = newDepth;
                                }
                                if (newDepth > dc.height) {
                                    newDepth = dc.height;
                                    radius = 0;
                                }
                                if (inflatedPattern.length <= 1) {
                                    radius = 0;
                                }
                                
    
                                dc.dopeCommands[i].pattern = inflatedPattern;
                                dc.dopeCommands[i].depth = newDepth;
                                dc.dopeCommands[i].cornerRadius = radius;
                                dc.dopeCommands[i].thickness = newDepth;
                            } else {
                                // side dope
                                const inflate = diffusionStack.inflate - dc.dopeCommands[i].thickness - 1;
                                if (inflate >= 0) {
                                    let inflatedPattern = inflatePattern(pattern, width, inflate);
                                    inflatedPattern = intersectPatterns(inflatedPattern, parentPattern);

                                    dc.dopeCommands[i].pattern = inflatedPattern;
                                    dc.dopeCommands[i].thickness = dc.dopeCommands[i].thickness + diffusionStack.inflate;
                                }
                                
                            }
                            
                        }
                    }
                }
            } else if (stackAction.type === StackChange.Flip) {
                if (this.drawCommands.length > 0) {
                    this.drawCommands.sort(compareByZandHeight);

                    const topZ = this.drawCommands[this.drawCommands.length - 1].z - this.drawCommands[this.drawCommands.length - 1].height;

                    this.drawCommands.reverse();

                    for (let i = 0; i < this.drawCommands.length; i++) {
                        this.drawCommands[i].z = this.maxHeight - this.drawCommands[i].z + topZ + this.drawCommands[i].height;
                        // is this needed?
                        this.drawCommands[i].uniqueKey = this.drawCommands[i].uniqueKey + "-flipped";

                        // update dopeCommands
                        const dopeCommands = this.drawCommands[i].dopeCommands;
                        if (dopeCommands) {
                            for (let j = 0; j < dopeCommands.length; j++) {
                                dopeCommands[j].flipped = !dopeCommands[j].flipped;
                            }
                            this.drawCommands[i].dopeCommands = dopeCommands;
                        }
                    }
                }
            } else if (stackAction.type === StackChange.HardBake) {
                for (let i = 0; i < this.drawCommands.length; i++) {
                    if (this.drawCommands[i].resistType !== ResistType.NONE) {
                        this.drawCommands[i].type = "Pattern";
                        this.drawCommands[i].resistType = ResistType.NONE;
                        this.drawCommands[i].color = this.drawCommands[i].color.getSlightlyDarker();
                    }
                }
            }
        }

        this.drawCommands.sort(compareByZandHeight);
        let minHeight = this.maxHeight;
        if (this.drawCommands.length > 0) {
            minHeight = this.drawCommands[this.drawCommands.length - 1].z - this.drawCommands[this.drawCommands.length - 1].height;
        }
        const stackHeight = this.maxHeight - minHeight;

        // TODO: dynamic viewbox
        const polygons: JSX.Element[] = [];
        if (this.threeDim) {
            polygons.push(<g key="3d-top">{ this.drawCommands.map(dc => this.drawService.getDrawing(dc, DrawingFace.Top)) }</g>);
            polygons.push(<g key="3d-side">{ this.drawCommands.map(dc => this.drawService.getDrawing(dc, DrawingFace.Side)) }</g>);
            polygons.push(<g key="3d-front">{ this.drawCommands.map(dc => this.drawService.getDrawing(dc, DrawingFace.Front)) }</g>);
        } else {
            polygons.push(<g key="2d">{ this.drawCommands.map(dc => this.drawService.getDrawing(dc, DrawingFace.Front)) }</g>);
        }
        
        const ret: SGVEngineReturnType = {
            svg : (<svg className={this.getClassName(svgDisplayMode)} viewBox={this.getViewBox(threeDim, width, svgDisplayMode, stackHeight)}>
                  { polygons }
                  { svgDisplayMode === SVGDisplayMode.Thumbnail ? <></> : this.drawCommands.map(dc => this.drawService.getLabel(dc)) }
                </svg>),
            history: this.drawCommands
        }
        return ret;
    }

    generate(stackActions: StackAction[], history: DrawCommand[], threeDim: boolean, svgDisplayMode: SVGDisplayMode): SGVEngineReturnType {
        try {
            return this.generateSVG(stackActions, cloneDeep(history), threeDim, svgDisplayMode);
        } catch (e) {
            console.error(e);
            const ret: SGVEngineReturnType = {
                svg : (<svg className={this.getClassName(svgDisplayMode)} viewBox={this.getViewBox(threeDim, 100, svgDisplayMode, 100)}>
                    <text y="50">
                        <tspan x="50%" textAnchor="middle"> TOO </tspan>
                        <tspan x="50%" dy="15" textAnchor="middle"> COMPLEX </tspan>
                    </text>
                </svg>),
                history: history
            }
            return ret;
        }
    }
}


export {} //to remove
