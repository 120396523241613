import { Button, ButtonGroup, Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { Tooltip2 } from "@blueprintjs/popover2";
import { useCallback } from "react";
import { ContactDialog } from "../dialogs/ContactDialog";

interface Props {
  title: string,
  description: string,
  buttonTxt: string,
  link: string
} 

const buttonStyle = {
  marginTop: '10px',
  background: 'rgba(150, 86, 180)',
  color: 'white',
  fontSize: 'min(3vw, 20px)',
};

export const TextBox: React.FC<Props> = ({ title, description, buttonTxt, link }) => {
  return (
    <div className={'text-box'}>
      <h2>{title}</h2>
      <p>{description}</p>
      <a href={link}>
      <Button style={buttonStyle} large={true} text={buttonTxt}/>
      </a>
    </div>
  )
}

export const EmailBox: React.FC<Props> = ({ title, description, buttonTxt, link }) => {

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText('hello@fabublox.com');
  }, []);

  return (
    <div className={'text-box'}>
      <h2>{title}</h2>
      <p>{description}</p>
      <ButtonGroup >
        <ContactDialog>
          {(handleOpen: () => void) => (
            <Button onClick={handleOpen} style={buttonStyle} text={buttonTxt} large={true} />
          )}
        </ContactDialog>
        <Tooltip2 content={"Copy email to clipboard"}>
          <Button onClick={handleCopyClick} style={buttonStyle} large={true}>
            <Icon style={{ paddingBottom: '5px' }} icon={IconNames.CLIPBOARD} color={'white'} />
          </Button>
        </Tooltip2>
      </ButtonGroup>
    </div>
  )
}