import { Classes, H4, H6 } from "@blueprintjs/core"
import { Column } from "../Layout/layouts"

export const TeamListing: React.FC = () => {
  return (
    <div className={'team-listing'}>
      <h2>Our Team</h2>
      <div className='team-wrap'>
        <div className='team-box'>
          <img style={{ width: '30%' }} src='jan_headshot.png' alt='Logo' />
          <Column className='description'>
            <H4 style={{marginTop: '80px'}}>Jan Tiepelt</H4>
            <H6>Project Lead, UI/UX Design</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              PhD Candidate, EECS, MIT <br/>
MSc Materials Science & Engineering,<br/>
ETH Zurich<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Nano- and microfabrication expert <br/>
8+ years of cleanroom experience
              </p>
            </Column>
          </Column>
        </div>
        <div className='team-box'>
          <img style={{ width: '30%' }} src='jack_headshot.png' alt='Logo' />
          <Column className='description'>
          <H4 style={{marginTop: '80px'}}>Jack Muller</H4>
            <H6>Lead Software Engineer</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              Former Engineering Manager<br/>
              BA Computer Science,<br/> Amherst College<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Professional full-stack developer leading agile teams to market-ready solutions
              </p>
            </Column>
          </Column>
        </div>
        <div className='team-box'>
          <img style={{ width: '30%' }} src='eyal_headshot.png' alt='Logo' />
          <Column className='description'>
            <H4 style={{marginTop: '80px'}}>Eyal Perry</H4>
            <H6>Algorithms Engineering Lead</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              PhD Student, Media Lab, MIT <br/>
              BSc Computer Science & Biology,<br/>
              Tel Aviv University<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Data science & visualizations<br/>
10+ years of programming experience
              </p>
            </Column>
          </Column>
        </div>
        <div className='team-box'>
          <img style={{ width: '30%' }} src='josh_headshot.png' alt='Logo' />
          <Column className='description'>
            <H4 style={{marginTop: '80px'}}>Josh Perozek</H4>
            <H6>Process & Simulations Lead</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              PhD Candidate, EECS, MIT <br/>
              BSc Electrical Engineering,<br/>
              U of I at Urbana-Champaign<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Semiconductor & transistor fab expert <br/>
3+ years MIT Process Tech Committee
              </p>
            </Column>
          </Column>
        </div>
      </div>
    </div>
  )
}
