import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface CustomClean extends BaseBlox {
    // to do user select number of cleaning steps
    cleanChemical: string | null;
    cleanTime: number | null;
    cleanTimeUnit: Units | null;
    cleanTemp: number | null;
    cleanTempUnit: Units | null;
    ultraSonication: boolean | null;
    // SEMIFAB
    layerLabelsToRemove: string[] | null;
    keepFloatingStructures: boolean | null;
    layerLabelsToFloat: string[] | null;
    layerLabelsToFloatDisabled: () => boolean | null;
}

export const customCleanDisplay: DisplayMap = {
    cleanChemical: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cleaning Agent",
        placeholder: "Enter chemical name",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
      },
    cleanTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cleaning Time",
        placeholder: "Enter cleaning time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    cleanTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cleaning Temperature",
        placeholder: "Enter cleaning temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    ultraSonication: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Ultra-Sonication",
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    // SEMIFAB
    layerLabelsToRemove: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Remove",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: "This will fully remove the selected layers even if they are buried."
    },
    keepFloatingStructures: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Keep Floating Structures",
        order: 5,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: false
    },
    layerLabelsToFloat: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Float",
        order: 9,
        tabs: [Tab.SEMIFAB],
    },
}

export const getCustomClean = ({stepNumber}: BloxArgs): CustomClean => ({
        name: `Custom Clean ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.CustomClean,
        cleanChemical: null,
        cleanTime: null,
        cleanTimeUnit: Units.MIN,
        cleanTemp: null,
        cleanTempUnit: Units.CELSIUS,
        ultraSonication: false,
        commentField: null,
        customFields: {},
        // semifab
        layerLabelsToRemove: [],
        layerLabelsToFloat: [],
        keepFloatingStructures: false,
        layerLabelsToFloatDisabled: function () {
            return !this.keepFloatingStructures ?? false;
        },
    }
);
