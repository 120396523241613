import { useCallback, useContext } from 'react';
import { useFabuState } from '../../hooks/state/use-fabu-state';
import { Section } from '../../__generated__/Process';
import { v4 as uuidv4 } from 'uuid';
import { UnsavedChangesContext } from '../../hooks/state/unsaved-changes-provider';

const getSectionId = (bloxId: string, sections: Section[]) => {
    
    for (const section of sections) {
        if (section.bloxIds.includes(bloxId)) {
            return section.sectionId;
        }
    }
    return '';
}

export const useSectionManager = () => {
    const [, setSections] = useFabuState('processSections');
    const [processBloxes] = useFabuState('processBloxes');
    const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);
    const createSection = useCallback((previousSectionId: string, bloxId?: string) => {
        const newSectionId = uuidv4();
        setEditorUnsavedChanges(true);
        setSections(prevSections => {
            const sectionIndex = prevSections.findIndex(section => section.sectionId === previousSectionId);
            if (sectionIndex === -1) return prevSections; // Section not found

            const newSections = [...prevSections];
            const newBloxIds = bloxId ?
                prevSections[sectionIndex].bloxIds.splice(prevSections[sectionIndex].bloxIds.indexOf(bloxId) + 1) :
                [];

            const newSection = {
                sectionName: undefined,
                sectionId: newSectionId,
                sectionDesc: undefined,
                bloxIds: newBloxIds,
            };

            // Insert new section after the specified section
            newSections.splice(sectionIndex + 1, 0, newSection);

            return newSections;
        });
        return newSectionId;
    }, [setSections, setEditorUnsavedChanges]);

    const rejoinSection = useCallback((sectionId: string) => {
        setEditorUnsavedChanges(true);
        setSections(prevSections => {
            const sectionIndex = prevSections.findIndex(section => section.sectionId === sectionId);
            if (sectionIndex === -1 || sectionIndex === 0) return prevSections; // Section not found or is the first section

            const newSections = [...prevSections];

            // Move bloxIds from the section to the previous section
            newSections[sectionIndex - 1].bloxIds.push(...newSections[sectionIndex].bloxIds);

            // Remove the specified section
            newSections.splice(sectionIndex, 1);

            return newSections;
        });
    }, [setSections, setEditorUnsavedChanges]);

    const addBloxToSection = useCallback((targetIdx: number, bloxId?: string, emptySectionId?: string) => {
        const previousBlox = processBloxes[targetIdx];
        if (!previousBlox || bloxId === undefined ) {
            console.error("Unable to update section from blox addition");
            return;
        }
        setSections(prevSections => {
            const targetSectionId = getSectionId(previousBlox.id, prevSections);
            // sourceSectionId will only exist if an existing blox is being moved
            const sourceSectionId = getSectionId(bloxId, prevSections);
                return prevSections.map(section => {
                    const newBloxIds = [...section.bloxIds];

                    // remove if there is a source
                    if (section.sectionId === sourceSectionId) {
                        const removeIdx = newBloxIds.indexOf(bloxId);
                        newBloxIds.splice(removeIdx, 1);
                    }
                    // insert
                    if (emptySectionId && section.sectionId === emptySectionId) {
                        newBloxIds.push(bloxId);
                    } else if (!emptySectionId && section.sectionId === targetSectionId) {
                        const insertIndex = newBloxIds.indexOf(previousBlox.id);
                        if (insertIndex === -1) return section;
                        newBloxIds.splice(insertIndex + 1, 0, bloxId);
                    }
                    
                    return { ...section, bloxIds: newBloxIds };
                });
        });
    }, [setSections, processBloxes]);

    const insertBloxIdsToSection = useCallback((sectionId: string, targetIdx: number, bloxIds: string[]) => {
        setSections(prevSections => {
            return prevSections.map(section => {
                if (section.sectionId === sectionId) {
                    const newBloxIds = [...section.bloxIds];
                    newBloxIds.splice(targetIdx + 1, 0, ...bloxIds);
                    return { ...section, bloxIds: newBloxIds };
                } else {
                    return section;
                }
            });
        });
    }, [setSections]);
    

    const deleteBloxFromSection = useCallback((bloxId: string) => {
        setSections(prevSections => {
            const sectionId = getSectionId(bloxId, prevSections);

            return prevSections.map(section => {
                if (section.sectionId === sectionId) {
                    const bloxIndex = section.bloxIds.indexOf(bloxId);
                    if (bloxIndex > -1) {
                        section.bloxIds.splice(bloxIndex, 1);
                    }
                    return { ...section, bloxIds: section.bloxIds };
                }
                return section;
            });
        });
    }, [setSections]);

    const deleteSection = useCallback((sectionId: string) => {
        setEditorUnsavedChanges(true);
        setSections(prevSections => {
            const sectionIndex = prevSections.findIndex(section => section.sectionId === sectionId);
            if (sectionIndex === -1) return prevSections; // Section not found

            const newSections = [...prevSections];
            newSections.splice(sectionIndex, 1);

            return newSections;
        });
    }, [setSections, setEditorUnsavedChanges]);

    return { createSection, rejoinSection, addBloxToSection, insertBloxIdsToSection, deleteBloxFromSection, deleteSection };
};
