import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface Bake extends BaseBlox {
    bakeTemp: number | null;
    bakeTempUnit: Units | null;
    bakeTime: number | null;
    bakeTimeUnit: Units | null;
    hardBake: boolean | null;
}

export const bakeDisplay: DisplayMap = {
    hardBake: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Hard-Bake Resist",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: "Selecting this value will remove the photo-active properties of any resists so they can not be exposed in future steps."
    },
    
    bakeTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Bake Temperature",
        placeholder: "Enter bake temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    bakeTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Bake Time",
        placeholder: "Enter bake time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
}

export const getBake = ({stepNumber}: BloxArgs): Bake => ({
        name: `Bake ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.Bake,
        bakeTemp: null,
        bakeTempUnit: Units.CELSIUS,
        bakeTime: null,
        bakeTimeUnit: Units.SEC,
        hardBake: false,
        commentField: null,
        customFields: {}
    }
);
