import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface APCVD extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    depRate: number | null;
    depRateUnit: Units | null;
    subTemp: number | null;
    subTempUnit: Units | null;
    showerTemp: number | null;
    showerTempUnit: Units | null;
    precursors: string | null;
    precursorFlow: string | null;
    // DISPLAY
    doubleSided: boolean | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
}

export const apCVDDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposited Material",
        placeholder: "Enter material to deposit",
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    depRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Rate",
        placeholder: "Enter deposition rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.UMPERMIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    subTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Substrate Temperature",
        placeholder: "Enter substrate temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    showerTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Showerhead Temperature",
        placeholder: "Enter showerhead temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursors: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursors",
        placeholder: "Enter precursor names",
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursorFlow: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor Flows",
        placeholder: "Enter all precursor flows",
        order: 11,
        tabs: [Tab.EXPERIMENTAL],
    },

    
    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    }
}

export const getAPCVD = ({stepNumber}: BloxArgs): APCVD => ({
        name: `AP-CVD ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.APCVD,
        material: null,
        doubleSided: false,
        thickness: null,
        thicknessUnit: Units.ANG,
        depRate: null,
        depRateUnit: Units.ANGPERSEC,
        precursors: null,
        precursorFlow: null,
        subTemp: null,
        subTempUnit: Units.CELSIUS,
        showerTemp: null,
        showerTempUnit: Units.CELSIUS,
        commentField: null,
        customFields: {},
        layerColor: {R: 100, G: 125, B: 192, A: 1},
        layerThickness: 8,
        sidewallThickness: 60,
        layerLabel: null,
});
