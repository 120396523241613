import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import {Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { unionTypeAnnotation } from "@babel/types";
import { SputterDialogContent, SputterAngularDistDialogContent, SputterStepSizeDialogContent, SputterInterpDialogContent, SputterTargetAngleDialogContent, SputterIsoRatioDialogContent} from "../../dialogs/SputterDialogContent";

export interface Sputter extends BaseBlox {
    sputterMaterial: string | null;
    materialId: string;
    sputterThickness: number | null;
    sputterThicknessUnit: Units | null;
    sputterRate: number | null;
    sputterRateUnit: Units | null;
    sputterTime: number | null;
    sputterTimeUnit: Units | null;
    sputterType: SputterType | null;
    rfDcSputterPower: number | null;
    rfDcSputterPowerUnit: Units | null;
    sputterGasses: string | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    // SEMIFAB
    isConformal: boolean | null;
    angularDist: number | null;
    angularDistUnit: Units | null;
    angularDistDisabled: () => boolean | null;
    stepSize: number | null;
    stepSizeUnit: Units | null;
    stepSizeDisabled: () => boolean | null;
    surfaceInterp: number | null;
    surfaceInterpUnit: Units | null;
    surfaceInterpDisabled: () => boolean | null;
    targetAngle: number | null;
    targetAngleUnit: Units | null;
    targetAngleDisabled: () => boolean | null;
    isotropyRatio: number | null;
}

export enum SputterType {
    RFSPUTTER = "RF Sputtering",
    DCSPUTTER = "DC Sputtering",
    NONE = "Select Sputter Type"
}

export const sputterDisplay: DisplayMap = {
    sputterMaterial: {
        fieldType: DisplayFieldTypes.Input,
        label: "Material",
        placeholder: "Enter material to deposit",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    sputterThickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    sputterRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Rate",
        placeholder: "Enter deposition rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.UMPERMIN],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    sputterTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Time",
        placeholder: "Enter deposition time",
        isNumber: true,
        units: [Units.SEC,Units.MIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    sputterType: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Sputter Type",
        options: [SputterType.NONE,SputterType.RFSPUTTER,SputterType.DCSPUTTER], // if none selected default to positive
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    rfDcSputterPower: {
        fieldType: DisplayFieldTypes.Input,
        label: "RF or DC Power",
        placeholder: "Enter RF or DC power",
        isNumber: true,
        units: [Units.WATT],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    sputterGasses: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sputter Gas Composition",
        placeholder: "Enter gas flows and ratios",
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },

    // DISPLAY
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 50
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 0,
        tabs: [Tab.DISPLAY],
    },
    // SEMIFAB
    isConformal: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Conformal",
        order: 0,
        tabs: [Tab.SEMIFAB],
        infoContent: SputterDialogContent,
    },
    angularDist: {
        fieldType: DisplayFieldTypes.Input,
        label: "Maximum Deposition Angle",
        placeholder: "Enter maximum deposition angle",
        isNumber: true,
        units: [Units.DEG],
        order: 7,
        tabs: [Tab.SEMIFAB],
        infoContent: SputterAngularDistDialogContent
    },
    stepSize: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Step Size",
        placeholder: "Enter deposition step size",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
        infoContent: SputterStepSizeDialogContent
    },
    surfaceInterp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Surface Interpolation",
        placeholder: "Enter surface interpolation distance",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
        infoContent: SputterInterpDialogContent
    },
    targetAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Target Angle",
        placeholder: "Enter target angle",
        isNumber: true,
        units: [Units.DEG],
        order: 7,
        tabs: [Tab.SEMIFAB],
        infoContent: SputterTargetAngleDialogContent,
    },
    isotropyRatio: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Isotropy Ratio",
        order: 6,
        tabs: [Tab.SEMIFAB],
        defaultValue: 0,
        infoContent: SputterIsoRatioDialogContent,
    },
}

export const getSputter = ({stepNumber}: BloxArgs): Sputter => ({
        name: `Sputter ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.Sputter,
        sputterMaterial: null,
        materialId: uuidv4(),
        sputterGasses: null,
        sputterThickness: null,
        sputterThicknessUnit: Units.NM,
        sputterRate: null,
        sputterRateUnit: Units.ANGPERSEC,
        sputterTime: null,
        sputterTimeUnit: Units.SEC,
        sputterType: SputterType.NONE,
        rfDcSputterPower: null,
        rfDcSputterPowerUnit: Units.WATT,
        commentField: null,
        layerColor: {R: 140, G: 79, B: 225, A: 1},
        layerThickness: 8,
        sidewallThickness: 40,
        layerLabel: null,
        customFields: {},
        // SEMIFAB
        isConformal: false,
        angularDist: 85,
        angularDistUnit: Units.DEG,
        angularDistDisabled: function () {
            return this.isConformal ?? false;
        },
        stepSize: 0,
        stepSizeUnit: Units.NM,
        stepSizeDisabled: function () {
            return this.isConformal ?? false;
        },
        surfaceInterp: 0,
        surfaceInterpUnit: Units.NM,
        surfaceInterpDisabled: function () {
            return this.isConformal ?? false;
        },
        targetAngle: 0,
        targetAngleUnit: Units.DEG,
        targetAngleDisabled: function () {
            return this.isConformal ?? false;
        },
        isotropyRatio: 0.05,
});
