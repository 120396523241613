export enum DisplayFieldTypes {
    EditableText,
    Input,
    Multiline,
    Dropdown,
    MultiMaterialSelect,
    MaterialDropdown,
    Image,
    Switch,
    Color,
    PatternInput,
    Slider,
    PercentageSlider
}

export enum Tab {
    EXPERIMENTAL = "Experimental",
    DISPLAY = "Stack Display",
    SEMIFAB = "Semifab"
}

export enum Draggables {
    Blox = "BLOX",
    Module = "MODULE"
}

export enum Units {
    // length scales
    ANG = "Å",
    NM = "nm",
    MICRON = "µm",
    MM = "mm",
    CM = "cm",
    DEG = "°",
    //volume
    NL = "nl",
    UL = "µl",
    ML = "ml",
    LIT = "l", 
    //time
    FEMTOSEC = "fs",
    PICOSEC = "ps",
    NANOSEC = "ns",
    MICROSEC = "µs",
    MILLISEC = "ms",
    SEC = "secs",
    MIN = "mins",
    HOUR = "hours",
    // spin speed
    RPM = "rpm",
    // temperature
    CELSIUS = "°C",
    KELVIN = "K",
    //length scale rates
    ANGPERSEC = "Å/s",
    NMPERSEC = "nm/s",
    NMPERMIN = "nm/min",
    UMPERMIN = "µm/min",
    UMPERHOUR = "µm/h",
    // volume/flow rates
    LITPERMIN = "l/min",
    MILLILITPERMIN = "ml/min",
    GPM = "GPM",
    //Electricity
    PICOAMP = "pA",
    NANOAMP = "nA",
    MICROAMP = "µA",
    MILLIAMP = "mA",
    AMP = "Amps",
    VOLT = "Volts",
    KILOVOLT = "kV",
    OHM = "Ω",
    KILOOHM = "kΩ",
    WATT = "Watts",   
    //Energy
    MILLIJOULES = "mJ",
    JOULES = "J",
    ELECVOLT = "eV",
    KILOELECVOLT = "keV",
    MEGAELECVOLT = "MeV",
    //Intensity
    MWPERSQCM = "mW/cm²", // \xB2 also works for superscript 2
    //exposure dose
    MILLIJOULPERSQCM = "mJ/cm²",
    UCOULPERSQCM = "µC/cm²",
    // pressure
    TORR = "Torr",
    PASCAL = "Pa",
    KILOPASCAL = "kPa",
    MILLIBAR = "mbar",
    BAR = "bar",
    NEWTPERM = "N/m²",

    PSI = "psi",
    // unitless
    PERCENT = "%",
    //concentration
    PERSQCM = "cm⁻²"

}


export enum StackChange {
    MultiDeposit,
    FillDeposit,
    ThinDeposit,
    WaferBonding,
    StripResist,
    WetEtch,
    LiftOff,
    Clean,
    Etch,
    Polish,
    Develop,
    Pattern,
    Imprint,
    Flip,
    IonImplantation,
    DopingDiffusion,
    HardBake,
    ThermalOxidation,
    NotImplemented
}

export enum LibCategory {
    Clean = "Clean",
    Deposit = "Deposition",
    Lithography = "Lithography",
    Etch = "Etch",
    Doping = "Doping",
    Other = "Other"
}